<template>
  <v-card outlined color="grey lighten-2" class="pt-3">
    <v-alert class="over" dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
      {{ $t(alert_message) }}
    </v-alert>

    <v-form ref="form" lazy-validation :readonly="!isEditAllow">                
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field class="font_size_small" readonly dense
            v-model="editedItem.lane_id"
          >
            <v-icon :class="[statusClass]" slot="prepend">
              mdi-road
            </v-icon>
        </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.lane_name"
            v-bind:label="$t('Name')"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.lane_desc"
            v-bind:label="$t('Description')"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.location"
            v-bind:label="$t('Location (lat, long)')"
            :hint="locationHint"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field class="font_size_small" readonly dense
            v-model="editedItem.controller_id"
            v-bind:label="$t('Controller Id')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined small color="success" v-if="isEditAllow" @click="save"> {{ $t("Save") }} </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import API from './../api';

export default {
  name: 'LaneItem',
  props: {'item' : Object },
  data() {
    return {
      rules: {
        required: v => !!v  || 'Item is required',
        number: v => /^-?\d+(\.\d+)?$/.test(v) || 'Please enter a number'
      },
      alert_message: '',
      alert: false,
      alert_type: 'warning',
      loading: true,
      editedItem: { ...this.item}    
    };
  },
  watch: {
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},3000)
      }
    }
  },
  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    statusClass() {
      return this.item.state ? API.appConst.LIVE_STATUS_GOOD.type : API.appConst.LIVE_STATUS_FAIL.type
    },
    locationHint() {
      return (!API.appScope.validateLocation(this.editedItem.location)) ? 'The numbers must be must be decimal (-90 to 90 for latitude, -180 to 180 for longitude). Example: 38.8951, -77.0364' : ''
    }
  },
  methods: {   
    save () {
      const isValid = this.$refs.form.validate();
      if (!isValid) return

      this.editedItem.lane_location = this.editedItem.location;

      API.editLane(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.$emit('update', this.editedItem, 'lane');
            this.alert_type     = 'success';
            this.alert_message  = this.$t("Lane successfully changed");
            this.alert          = true;
          }
          else {
            this.alert_type    = 'warning';
            this.alert_message = this.$t("Changes not saved:") + response.data.error.message;
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Changes not saved:") + error.message;
          this.alert         = true;
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
}
</script>

<style scoped>
.row + .row { margin-top: 2px;}
.row.exp > div {
  padding: 1px!important;
  font-size: .9em;
}
button.tooltip { position: absolute; top: 12px;}
.btn-edit { padding-left: 2px!important;}
.v-card__actions { padding: 8px 10px!important;}

</style>
