<template>
  <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <div class="list justify-center mb-0 mt-2 pa-0">
      <section>
        <v-row class="pa-2">
          <v-col class="top">
            <v-select
              v-model="$store.state.selectedStatusTree"
              :items="statuses"
              v-on:change="filterByStatus()"
              item-text="text"
              item-value="status_class"
              hide-details
              single-line
              class="status_select"
            >
              <template v-slot:item="{ item }">
                <v-icon class="pa-1" :color="item.color"> mdi-circle</v-icon>{{$t(item.text)}}
              </template>
              <template #selection="{ item }">
                <v-avatar class="ma-1" :color="item.color" size="18">
                  <span>{{filteredSiteCount}}</span>
                </v-avatar>
                {{$t(item.text)}}
              </template>
            </v-select>
          </v-col>
          <v-col class="top">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              v-bind:label="$t('Search')"
              single-line
              hide-details
              clearable
              class="pa-0 shrink search-label"
            ></v-text-field>
          </v-col>
          <v-col class="top">
            <v-btn v-if="isEditAllow" outlined
                color="light-blue accent-4" dark small class="ma-1"
                :disabled = "!isCreateNewSiteAllow"
                @click="createNewSite()"
              >
                {{$t("New Site")}}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="!filteredSiteCount" d-flex no-gutters class="pa-2 pb-0 align-stretch" style="max-height: 82vh;">
          <v-col cols="12" md="2" class="pa-0 border"><p class="ma-2 mb-0 grey--text">{{ $t('No site available') }}</p></v-col>
          <v-col cols="12" md="10" class="pa-0 border">
            <SiteAlerts></SiteAlerts>
          </v-col>
        </v-row>
        <v-row v-else d-flex no-gutters class="pa-2 pb-0 align-stretch" style="height: 82vh;">
          <v-col cols="12" md="4" class="pa-0 border">
            <v-treeview
              :items="$store.state.treeFiltered"
              :search="search"
              item-key="id"
              @update:open="saveOpenNodes"
              dense
            >
              <template slot="label" slot-scope="{ item }">
                <v-icon v-if="item.type === 'site'" class="mr-1" :class="[item.status]">mdi-home-city-outline</v-icon>
                <v-icon v-if="item.type === 'controller'" class="mr-1" :class="[item.status]">mdi-card-bulleted-outline</v-icon>
                <v-icon v-if="item.type === 'lane'" class="mr-1" :class="[item.status]">mdi-road</v-icon>
                <v-icon v-if="item.type === 'camera'" class="mr-1" :class="[item.status]">mdi-camera-outline</v-icon>

                <v-icon v-if="item.type === 'controller' && item.warning" class="mr-1 fail shake-anim" small>mdi-alert-outline</v-icon>

                <a @click="clickItem($event, item)" :data-node-id="item.id">
                  {{item.name}}
                </a>
                <v-icon v-if="item.type === 'site'" class="mr-1 small" @click="createToken(item.detail)"
                  :color="item.detail.site_token ? 'light-blue accent-4' : 'grey lighten-1'"
                  :title="$t('Token')">
                  mdi-key-variant
                </v-icon>
              </template>
            </v-treeview>
          </v-col>

          <v-col cols="12" md="4" class="pa-0 item border">
            <SiteItem v-if="curr_item.type === 'site'" :key="curr_item.id" :item="curr_item.detail" @update="updateTreeByResponse" @add="addTreeByResponse" @cancel="cancelCreateNewSite" @delete="deleteSite"></SiteItem>
            <ControllerItem v-if="curr_item.type === 'controller'" :key="curr_item.id" :item="curr_item.detail" @update="updateTreeByResponse"  @delete="deleteController"></ControllerItem>
            <LaneItem v-if="curr_item.type === 'lane' && curr_item.id !== 0" :key="curr_item.id" :item="curr_item.detail" @update="updateTreeByResponse"></LaneItem>
            <CameraItem  v-if="curr_item.type === 'camera'" :key="curr_item.id" :item="curr_item.detail" @update="updateTreeByResponse"></CameraItem>
            <MapItem v-if="curr_item.id && curr_item.type" :key="curr_item.type+curr_item.id" :location=curr_item.detail.location></MapItem>
            <!--<CameraImage v-if="curr_item.type === 'camera'" :key="'img_'+curr_item.id"></CameraImage>-->

          </v-col>
          <v-col cols="12" md="4" class="pa-0 border">
            <SiteAlerts></SiteAlerts>
          </v-col>

          <TokenItem :visible="showTokenItem" @close="closeTokenItem" @save="updateItemFromTokenItem" :item="curr_site"></TokenItem>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Errored from './Errored.vue';
import API from '../api';
import MapItem from './MapItem.vue';
import TokenItem from './TokenItem.vue';
import SiteItem from './SiteItem.vue';
import ControllerItem from './ControllerItem.vue';
import LaneItem from './LaneItem.vue';
import CameraItem from './CameraItem.vue';
//import CameraImage from './CameraImage.vue';
import SiteAlerts from './SiteAlerts.vue';

export default {
  name: 'SiteTree',
  components: { MapItem, Errored, SiteItem, TokenItem, ControllerItem, LaneItem, CameraItem, SiteAlerts},
  data: () => ({
    headers: [     
      { text: 'Id',  align: 'center', value: 'site_id', sortable: false, filterable: false, width: '35px'},
      { text: 'Name', align: 'start', value: 'site_name'},
    ],
    status_good: API.appConst.LIVE_STATUS_GOOD.type,
    status_fail: API.appConst.LIVE_STATUS_FAIL.type,
    curr_site: {},
    curr_item: {},
    statuses: [
      { status_class: -1, color: '#bdbdbd', text: 'All' },
      { status_class: 'good',  color: '#4caf50', text: 'Online'},
      { status_class: 'fail', color: 'red', text: 'Offline'}
    ],
    search: null,
    showTokenItem: false,
    showMapItem: false,
    isNewItem: false,
    isDisabled: true,
    errored: false,
    error: '',
    updated: false
  }),
  mounted () {
    this.markCurrItemBySelector()
  },
  created () {
    if (this.filteredSiteCount)
    {
      this.curr_item = this.$store.state.treeFiltered[0];
    }

    this.$store.state.selectedStatusTree = -1;
    this.$store.state.reload = false;

    // after DOM rendered trick to simulate selecting the FIRST SITE when loading a component
    //this.$nextTick(() => { this.markCurrItemBySelector(); });
  },
  updated() {
    // trick to simulate selecting the FIRST SITE when changed filter by statuses
    if (this.filteredSiteCount && (this.$store.state.reload || !this.updated)) {
      let item = null;
      if (this.curr_item !== null) {
        item = API.appScope.findTreeItemById(this.$store.state.treeFiltered, this.curr_item.id);
      }

      if (!item) {
        item = this.$store.state.treeFiltered[0];
      }
      this.curr_item = item;

      this.markCurrItemBySelector();

      this.updated = true; // flag to prevent unnecessary tree updates
    }
  },
  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    isCreateNewSiteAllow () { 
      // is the previous process of creating a new site completed? (the new site must have id!=0 )
      if (this.filteredSiteCount) return (this.$store.state.treeFiltered[0].id !== 0) 
      return true
    },   
    filteredSiteCount () {
      return this.$store.state.treeFiltered.length
    }
  },
  methods: {
    saveOpenNodes(items) {
      // TODO: this will be useful when changing the filter by status or in other cases that require re-creating the tree
      if (items.length > 10) { console.log(items.length)}
    },  
    markCurrItemBySelector() {
      let item = this.curr_item;

      // remove selection on all elements
      Array.from(document.getElementsByClassName("selected_item")).forEach( (el => { el.classList.remove("selected_item"); }) )

      if (item == null) { return; }

      // mark new selection via dom
      let selector = `[data-node-id="${item.id}"]`; // selector by attribute 'data-node-id' = curr_item.id
      let itemNode = document.querySelectorAll(selector)[0];

      if (itemNode != null) {
        let itemParent = API.appScope.findParentWithClassName(itemNode, 'v-treeview-node__root');
        if (itemParent != null) { itemParent.classList.add("selected_item"); }
        else { console.log('MARK: itemParent not found'); }
      } else {
        console.log('MARK: itemNode not found');
      }
    },

    openNewSite () {
      this.showNewSite = true;
    },

    createToken (item) {
      this.curr_site = item;
      this.showTokenItem = true;
    },

    updateItemFromTokenItem (item) {
      let treeItem  = API.appScope.findTreeItemById(this.$store.state.treeFiltered, item.editedItem.site_id);
      if (treeItem) {
        treeItem.detail = {...item.editedItem };
      }      
    },

    closeTokenItem () {
      this.dialog = false
      this.showTokenItem = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.isReadOnly = true
      this.$refs.form ? this.$refs.form.resetValidation() : ''
    },

    clickItem (e, item) {  
      Array.from(document.getElementsByClassName("selected_item")).forEach( (el => { el.classList.remove("selected_item"); }) ) // remove class 'selected_item' on prev element
      let itemParent = API.appScope.findParentWithClassName(e.target, 'v-treeview-node__root');
      if (itemParent != null) { itemParent.classList.add("selected_item"); }
      this.curr_item = item;
    },

    cancelCreateNewSite() {
      this.updated = false;
      this.$store.state.treeFiltered.shift();
    },

    createNewSite() {
      this.$store.state.selectedStatusTree = -1;
      this.filterByStatus();

      let site = {
        site_name: '',
        site_desc: '',
        site_location: '',
        site_token: '',
        type: 'site',
        state: true
      }
      let newSite = {
          id: 0,
          name: this.$t('New site (not saved)'),
          detail: site,
          type: 'site',
          icon: 'mdi-home-city-outline',
          children:[],
          status: this.status_good
      }
      this.isNewItem = true;
      this.$store.state.treeFiltered.unshift(newSite);
      this.curr_item = this.$store.state.treeFiltered[0];
      this.updated = false;
    },

    updateTreeByResponse (item, type) {
      // To display the treeFiltered correctly, we need to find the object by 'id' and change its display property 'name'
      let id   = type + '_id';
      let name = type + '_name';
      let treeItem  = API.appScope.findTreeItemById(this.$store.state.treeFiltered, item[id]);

      if (treeItem) {
        treeItem.name   =  item[name];
        treeItem.detail = {...item };
      }
    },
    
    addTreeByResponse (item, type) {
      let id   = type + '_id';
      let name = type + '_name';
      const index = this.$store.state.tree.findIndex(obj => obj.id === item[id]);      
      if (index !== -1) {
        // this is MY new site. Already added via ws - need to delete
        this.$store.state.tree.splice(index, 1);
      }

      this.updated = false;
      this.$store.state.treeFiltered[0].id     = item[id];
      this.$store.state.treeFiltered[0].name   = item[name];
      this.$store.state.treeFiltered[0].detail = {...item };
    },

    deleteSite (item) {
      this.$store.commit('deleteSiteById',item.site_id)
      setTimeout(() => {
        this.filterByStatus()
      }, 500);
    },

    deleteController (item) {
      this.$store.commit('deleteControllerById',item.controller_id)
      setTimeout(() => {
        // delay for displaying information message
        this.filterByStatus()
      }, 500);
    },

    filterByStatus() {
      // let's check if a some unsaved site already exists? if yes, we will delete it
      let index = this.$store.state.treeFiltered.findIndex(item => item.id === 0);
      if (index !== -1) {
        this.$store.state.treeFiltered.splice(index, 1);
      }      

      this.$store.commit('filterTreeByStatus'); 
      this.updated = false;
    },
  },
}
</script>

<style scoped>
  ::v-deep .v-card__text {width: 350px;}
  ::v-deep .card_item {padding: 0 2px 0 2px!important;}
  ::v-deep .v-label { font-size: 16px; }
  .v-text-field input { font-size: 1em; padding: 0; }
  .v-input--selection-controls { margin-top: 0; padding-top: 0;}
  ::v-deep .v-select > .v-input__control > .v-input__slot { margin-bottom: 0px!important; }
  .custom-item { display: flex; align-items: center;}
  .custom-icon { margin-right: 8px;}
  ::v-deep .v-list-item__content { padding: 0; }
  ::v-deep .v-list-item__action { margin: 2px 6px 2px 2px!important; }
  ::v-deep .v-list-item__title { font-size: 12px; }
  ::v-deep .v-list-item--link { font-size: 12px; }
  ::v-deep .v-text-field { font-size: 12px; }
  .row.align-content-start > .col-6 { padding-right: 2px; }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 28px;
    min-width: 80px;
    padding: 0 5px;
    margin-right: 3px;
    font-size: 0.7rem;
  }
  button.v-icon.mdi.small { font-size: 18px!important;}
  .v-icon.mdi.mdi-alert-outline { font-size: 22px!important;}
  .v-card__subtitle, .v-card__text, .v-card__title { padding: 0px; }
  ::v-deep .v-treeview > .v-treeview-node.v-treeview-node--click { border-bottom: 1px solid lightgray;}
  ::v-deep .v-treeview > .v-treeview-node.v-treeview-node--click:last-child { border-bottom: none;}
  ::v-deep .v-treeview-node__label {
    display: flex;
    align-items: center;
  }
  .v-treeview-node__label > a {
    color: #424242;
    font-size: 1em;
    padding-right: 20px;
  }
  ::v-deep .v-treeview .v-treeview-node__root { min-height: 40px!important; }
  .v-treeview-node__root .v-treeview-node__label > a { 
    width: 200px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
  }
  .v-treeview-node__children .v-treeview-node__root .v-treeview-node__label > a { 
    width: 200px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
   }

  ::v-deep .selected_item {background-color: #e0e0e0;}
  .v-avatar {
  border-radius: 5px; 
  width: 30px!important;
  font-size: 11px;
}
  .v-avatar.ma-1 { top: 1px;}
  .status_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; margin-top: 14px}
  .v-input.status_select {
    max-width: 180px;
  }
  .top {
    display: flex;
    max-width: 180px;
    justify-content: flex-start;
    font-size: 12px;
    padding: 0px 12px 2px 0px!important;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
  }
  .align-stretch {
    display: flex;
    align-items: stretch;
  }
  .border { border: 1px solid #e0e0e0; max-height: 81vh; overflow: overlay; }
  .item { background-color:#e0e0e0;}

  .exclamation { 
    color: red;
    font-weight: 400;
    font-size: 20px;
    font-family: system-ui; 
  }
</style>
