var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-1 pt-2",attrs:{"outlined":"","color":"grey lighten-2"}},[(_vm.loading)?_c('v-text-field',{attrs:{"color":"success","loading":"","disabled":""}}):(_vm.position.lat && _vm.position.lng)?_c('gmap-map',{staticStyle:{"width":"100%","height":"285px"},attrs:{"zoom":_vm.options.zoom,"center":_vm.options.center,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false
    }}},[_c('gmap-marker',{attrs:{"position":_vm.position}})],1):_c('div',{staticClass:"warn"},[_vm._v(" "+_vm._s(_vm.$t('Check the location format.'))+" "+_vm._s(_vm.$t('The numbers must be must be decimal (-90 to 90 for latitude, -180 to 180 for longitude). Example: 38.8951, -77.0364'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }