<template>
  <v-dialog v-model="show" max-width="500px">
    <section>
      <v-card color="grey lighten-3" dense>
        <v-toolbar dark>
          <v-toolbar-title>{{ $t("Site Token") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small dark @click="show=false"> <v-icon small>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <v-alert class="over" dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
          {{ $t(alert_message) }}
        </v-alert>
        <v-card-text>
          <v-form ref="tokenForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-col cols="12" sm="12" md="12">
              <v-text-field readonly dense hide-details v-model="editedItem.site_id" v-bind:label="$t('Id')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field readonly dense hide-details v-model="editedItem.site_name" v-bind:label="$t('Name')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="editedItem.site_desc !==''">
              <v-text-field readonly dense hide-details v-model="editedItem.site_desc" v-bind:label="$t('Description')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-textarea readonly dense hide-details outlined v-model="editedItem.site_token" v-bind:label="$t('Token')"
                id="token" variant="underlined" rows="10"
              ></v-textarea>
            </v-col>
            <v-spacer></v-spacer>
            <v-card-actions class="long">
              <v-btn v-if="isEditAllow" @click="createToken" outlined color="primary">{{ $t("New Token") }}</v-btn>
              <v-btn v-if="isEditAllow" type='submit' outlined color="success" :disabled="!isTokenUpdated">{{ $t("Save") }}</v-btn>
              <v-btn @click="copyToClipboard" outlined color="primary" class="tooltip" :disabled="!isTokenSaved">
                <span class="tooltiptext">{{ $t('Copy to clipboard') }}</span>
                <v-icon small color="primary" @click="copyToClipboard">mdi-content-copy</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                :loading="loading_json"
                :disabled="loading_json || !isTokenSaved"
                color="blue-grey darken-3" 
                class="white--text"                 
                @click="downloadJson" 
              >
                {{ $t("Download") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </section>
  </v-dialog>
</template>


<script>
import API from './../api';

export default {
  name: "TokenItem",
  data() {
    return {
      alert_message: '',
      alert: false,
      alert_type: 'warning',
      error: '',
      valid: false,
      editedItem: {},
      isTokenUpdated : false, // update fact, whether saved or not
      isTokenSaved : (this.item.site_token != ''), // saved in database
      loading_json: false
    };
  },
  props: { 
    'visible' : Boolean,
    'item': Object
  },     
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
  },    
  watch: {
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},3000)
      }
    },
    visible(newVisible) {
      if (newVisible) {
        // Return editedItem to the original item
        this.editedItem = Object.assign({}, this.item);
        this.isTokenSaved = (this.item.site_token != '');
        this.isTokenUpdated = false;
      }
    }
  },
  methods: {
    save () {
      const isValid = this.$refs.tokenForm.validate();
      if (!isValid) return

      API.editSite(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.alert_type    = 'success';
            this.alert_message = this.$t("Token successfully saved");
            this.alert         = true;
            this.isTokenSaved  = true;
            
            this.$emit('save', {editedItem: this.editedItem});
          }
          else {
            this.alert_type    = 'error';
            this.alert_message = this.$t("Token saving error:") + response.data.error.message;
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Token saving error:") + error.message;
          this.alert         = true;
        })
    },

    createToken () {
      const isValid = this.$refs.tokenForm.validate();
      if (!isValid) return

      API.createToken(this.editedItem.site_id)
      .then(response => {
        if (response.data.isOk) {
          this.editedItem.site_token = response.data.data;

          this.alert_type     = 'success';
          this.alert_message  = this.$t("Token successfully created");
          this.alert          = true;
          this.isTokenUpdated = true;
          this.isTokenSaved   = false;
        }
        else {
          this.alert_type    = 'warning';
          this.alert_message = this.$t("Token creation error:") + response.data.error.message;
          this.alert         = true;
        }
      })
      .catch(error => {
        this.alert_type    = 'error';
        this.alert_message = this.$t("Token creation error:") + error.message;
        this.alert         = true;
      })
      .finally(() => {
        this.error = ''
      })
    },

    downloadJson()
    {
      this.loading_json = true;
      API.downloadJson(this.editedItem.site_id)
      .then(response => {
        var filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]

        var file = window.URL.createObjectURL(new Blob([response.data]));
        var url = document.createElement('a');
        url.href = file;
        url.setAttribute('download', filename);
        document.body.appendChild(url);
        url.click();
        URL.revokeObjectURL(url.href);

        this.alert_type    = 'success'
        this.alert_message = filename + this.$t("downloaded successfully");
        this.alert         = true
      })
      .catch(error => {
        this.alert_type    = 'error';
        this.alert_message = this.$t("Error getting json:") + error.message;
        this.alert         = true
      })
      .finally(() => {
          this.loading_json = false
      })
    },
    copyToClipboard() {
      const node = document.getElementById("token")
      node.focus()
      node.select()
      try {
        // TODO: document.execCommand is considered deprecated
        document.execCommand('copy');
        this.alert_type    = 'success'
        this.alert_message = 'Token copied to clipboard'
        this.alert         = true
      } 
      catch (err) {
        this.alert_type    = 'error'
        this.alert_message = 'Unable to copy token:', err
        this.alert         = true
      } 
    },
    resetForm () {
      this.$refs.tokenForm.reset()
      this.$refs.tokenForm.resetValidation()
    },
  }
};
</script>
<style scoped>
  .v-card.v-sheet.theme--light.blue.darken-2 {
    max-width: 500px;
    overflow: hidden;
  }
  .v-dialog {overflow-y: hidden!important;}
  .v-btn {min-width: 30px!important; padding: 0 8px!important; margin-bottom: 12px;}
  .v-card__text {padding: 16px 8px;}
  .v-card__actions {padding: 8px 12px;}
  .v-btn.v-btn--block { height: 40px; justify-content: space-between;}
  .v-alert.over {
    position: absolute;
    z-index: 999;
    width: 100%;
  }
  .long { flex-wrap: wrap;}
  .v-input.v-text-field {font-size: 14px!important;}
  .v-icon.mdi.mdi-content-copy {font-size: 24px!important;}
</style>
