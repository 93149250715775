<template>
  <section>
    <div class="list justify-center mt-0 pa-1 pt-5">
      <v-alert dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
        {{ $t(alert_message) }}
      </v-alert>
      <v-data-table 
        :headers="headers"
        :items="lanes"
        :search="search"
        :footer-props="{
            itemsPerPageText: $t('Rows per page:'), 
            pageText: $t('{0}-{1} of {2}'),
            'items-per-page-options':[30, 50, 100, { text: $t('All'), value: -1 }]
          }"
        :no-data-text="$t('No data available')"
        sort-by="lane_id"
        class="elevation-1 grey lighten-2 row-pointer"
        mobile-breakpoint="0"
        item-key="lane_id"
        single-select
        @click:row="rowClick"
      >
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>
    
        <template v-slot:top>
          <v-toolbar flat height="55px">
    
            <v-toolbar-title class="text-h9 ddd">{{ $t("Lane List") }}</v-toolbar-title>
            <v-spacer></v-spacer>         
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              v-bind:label="$t('Search')"
              single-line
              clearable
              class="pa-1 pt-4 shrink search-label"
            ></v-text-field>
    
            <v-dialog v-model="dialog" persistent hide-overlay max-width="500">
              <v-card color="grey lighten-3" class="lane_item">
                <v-toolbar dense dark>
                  <v-toolbar-title>{{ $t(formTitle) }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon small dark @click="dialog = false"> <v-icon small>mdi-close</v-icon> </v-btn>
                </v-toolbar>
    
                <v-form ref="form" lazy-validation :readonly="!isNewItem && isReadOnly">                
                  <v-container>
                    <v-row>
                      <v-col cols="10" sm="10" md="10" v-if="!isNewItem">
                        <v-text-field
                          v-model="editedItem.lane_id"
                          v-bind:label="$t('Id')"
                          class="font_size_small"
                          readonly dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2" v-if="!isNewItem && isEditAllow">
                        <v-btn  class="ma-0 pa-0 tooltip" color="blue darken-1" dark @click="isReadOnly = !isReadOnly">
                          <span class="tooltiptext">{{ $t('Edit') }}</span>
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.controller_id"
                          v-bind:label="$t('Controller Id')"
                          :rules="[rules.required]"
                          class="font_size_small"
                          readonly dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.lane_name"
                          v-bind:label="$t('Name')"
                          :rules="[rules.required]"
                          dense
                        >
                         <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.lane_desc"
                          v-bind:label="$t('Description')"
                          dense
                        >
                         <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.lane_location"
                          v-bind:label="$t('Location (lat, long)')"
                          dense
                        >
                          <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container> 
                
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success"        v-if="isEditAllow" @click="save" :disabled="!isNewItem && isReadOnly"> {{ $t("Save") }} </v-btn>
                    <v-btn color="grey lighten-1" v-if="isEditAllow" @click="close"> {{ $t("Cancel") }} </v-btn>
                    <v-btn color="grey lighten-1" v-else @click="close"> {{ $t("Close") }} </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>  
          </v-toolbar>
          <v-row>
            <v-col class="status_filter" cols="12" sm="12" md="12">
              <span>{{ $t('Filter by status') }}</span> 
              <v-select
                v-model="selectedStatus"
                :items="statuses"
                v-on:change="filterByStatus()"
                item-text="text"
                item-value="status_class"
                hide-details
                single-line
                class="status_select"
              >
                <template v-slot:item="{ item }">
                  <v-icon class="pa-1" :color="item.color"> mdi-circle</v-icon> {{$t(item.text)}}
                </template>
                <template #selection="{ item }">
                  <v-avatar class="ma-1" :color="item.color" size="18">
                    <span>{{filteredCameraCount}}</span>
                  </v-avatar>
                  {{$t(item.text)}}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">       
          <v-icon class="mr-2" @click="editItem(item)" :title="$t('Details')">mdi-file-document-outline</v-icon>
          <v-icon small class="mr-2" :class="[item.status]" :title="$t('Status')">mdi-circle</v-icon>   
        </template>
      </v-data-table>
    </div>
  </section>
</template>

<script>
import API from './../api';
import { eventBus } from '../main';

export default {
  name: 'LaneList',
  data: () => ({
    dialog: false,
    search: '',
    rules: {
      required: v => !!v  || 'Item is required',
      number: v => /^-?\d+(\.\d+)?$/.test(v) || 'Please enter a number'
    },
    headers: [
      //{ text: 'Id',  align: 'start', value: 'lane_id', width: '20%', filterable: false },
      { text: 'Name', align: 'start', value: 'lane_name', width: '15%' },
      { text: 'Description', align: 'start' , value: 'lane_desc', width: '20%', sortable: false },
      { text: 'Site', align: 'start' , value: 'site_name', width: '20%' },
      { text: 'Controller', align: 'start' , value: 'controller_name', width: '20%' },
      { text: 'Location', align: 'start' , value: 'lane_location', width: '15%'},
      //{ text: 'Controller', align: 'start' , value: 'controller_id', width: '20%' },
      //{ text: 'Lane', align: 'start' , value: 'lane_external_id' },
      { text: '', align: 'end', class: "pa-0", value: 'actions', sortable: false, filterable: false },
    ],
    lanes: [],
    lanesCopy: [],
    selectedId: 0,
    isReadOnly: true,
    editedIndex: -1,
    editedItem: {
      lane_id: '',
      lane_name: '',
      lane_location: '',
      lane_desc: '',
      controller_id: '',
      lane_site_id: ''
    },
    defaultItem: {
      lane_id: '',
      lane_name: '',
      lane_location: '',
      lane_desc: '',
      controller_id: '',
      lane_site_id: ''
    },
    statuses: [
      { status_class: -1, color: '#bdbdbd', text: 'All' },
      { status_class: 'good',  color: '#4caf50', text: 'Online'},
      { status_class: 'fail', color: 'red', text: 'Offline'}
    ],
    selectedStatus: -1,

    alert: false,
    alert_type: 'warning',
    alert_message: ''
  }),
  mounted () {
    this.initialize()
    eventBus.$on('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent);
  },
  beforeDestroy() {
    eventBus.$off('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent)
  },
  beforeRouteLeave(to, from, next) {
    eventBus.$off('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent)
    next()
  },
  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    isNewItem () {
      return this.editedIndex === -1 ? true : false
    },    
    formTitle () {
      return this.editedIndex === -1 ? 'New Lane' : 'Lane Info'
    },
    filteredCameraCount () {
      return this.lanes.length
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},5000)
      }
    }
  },
  methods: {   
    initialize () {
      if (this.selectedStatus != -1) {
        this.lanes = this.$store.state.lanes.filter(lane => lane.status === this.selectedStatus)  
      }
      else {
        this.lanes = this.$store.state.lanes
      }

      this.lanesCopy = [...this.lanes]
    },

    onCameraStateUpdatedEvent() {
      this.filterByStatus() 
    },

    filterByStatus() {
      if (this.selectedStatus != -1) {
        this.lanes = this.$store.state.lanes.filter(lane => lane.status === this.selectedStatus)
      }
      else {
        this.lanes = this.$store.state.lanes
      }
    },

    editItem (item) {
      this.editedIndex = this.lanes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close () {
      this.dialog = false
      
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
     
      this.isReadOnly = true
      this.$refs.form.resetValidation()
    },

    save () {
      const isValid = this.$refs.form.validate();
      if (!isValid) return

      if (this.editedIndex > -1) {
        // edit Lane
        API.editLane(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            Object.assign(this.$store.state.lanes[this.editedIndex], this.editedItem)
            this.alert_type    = 'success';
            this.alert_message = "Lane successfully changed";
            this.alert         = true
          }
          else {
            this.alert_type    = 'warning';
            this.alert_message = this.$t("Changes not saved:") + response.data.error.message;
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'warning';
          this.alert_message = this.$t("Changes not saved:") + error;
          this.alert         = true;
        })
        .finally(() => {
          this.close()
        })
      }
    },

    rowClick (item, row) {      
      row.select(true);
    }
  },
}
</script>

<style>
  .error--text > * { color: red!important; }
  .error--text .theme--light.v-label { color: red!important; }
  .lane_item .v-text-field { padding-top: 0;}
  .lane_item .v-btn { padding: 0 10px;}
  .lane_item .btn-edit { padding-bottom: 18px; padding-right: 4px; text-align: end;}
  .lane_item > .v-input__control > .v-input__slot { margin-bottom: 2px;}
  ::v-deep .lane_item .v-text-field__slot input { padding-bottom: 2px!important;}
  .v-toolbar__title.text-h9.ddd {background-color: #ddd;}
  .status_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; margin-top: 14px}
  .v-avatar {
    border-radius: 5px; 
    width: 30px!important;
    font-size: 11px;
  }
  .v-input.status_select {
    width: 150px;
    max-width: 150px;
    padding-left: 8px;
  }
  .status_filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 4px!important;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
  } 
  .v-input.status_select {
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
  }
  .v-list-item--link {
    font-size: 12px;
  }
</style>
