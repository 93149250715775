<template>
    <div class="list justify-center mt-0 pa-0">
      <SetupTree></SetupTree>
    </div>
  </template>
  
  <script>
  import SetupTree from '../components/SetupTree.vue';
  
  export default {
    name: 'Site',
    components: { SetupTree },
    data() {
      return {
      };
    } 
  }
  </script>