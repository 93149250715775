<template>
  <v-card class="login elevation-2 outlined mx-auto my-12" 
          max-width="350"
          color="gray lighten-3"
          >
    <v-toolbar dark>
      <v-toolbar-title>{{ $t("Login form") }}</v-toolbar-title>
    </v-toolbar>

     <v-card-text>
         <v-form ref="loginForm"
                  autocomplete="off"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="login()"
         >
          <v-text-field color="success" autocomplete="off"
              v-model="user"
              :rules="[rules.required]"
              v-bind:label="$t('Login')"
              class="shrink"
              prepend-icon="mdi-account-edit"
              @input = "error = false"
              :readonly="isFieldsReadOnly"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
          <v-text-field color="success" autocomplete="off"
              v-model="password"
              :rules="[rules.required]"
              v-bind:label="$t('Password')"
              class="shrink"
              type="password"
              prepend-icon="mdi-lock"
              @input = "error = false"
              :readonly="isFieldsReadOnly"
          >
           <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
          
          <div v-if="error" class="auth-error error--text">
            {{ $t(error) }}
          </div>
          <v-spacer></v-spacer>
          <v-btn type='submit' color="success">{{ $t("Login") }}</v-btn>
        </v-form>
     </v-card-text>
  </v-card>
</template>


<script>
import API from './../api';

export default {
  name: "Login",
  data() {
    return {
      isFieldsReadOnly: true,
      user: "",
      password: "",
      error: false,
      valid: false,
      rules: {
        required: v => !!v  || 'Item is required',
      },
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
    };
  },
  mounted () {
    // trick to avoid autofilling fields in Edge
    setTimeout(() => {
      if (API.appScope.isLoggedIn()) {
        this.$router.push('/site').catch();
      }
      this.isFieldsReadOnly = false;
    }, 500);
    this.$store.state.reload = false;
  },
  methods: {
    login () {
      const { user, password } = this

      const isValid = this.$refs.loginForm.validate();
      if (!isValid) return

      API.login({ user, password })
      .then(resp => {
        const token = resp.data.accessToken
        const tokenRefresh = resp.data.refreshToken
        if (token) {
          API.appScope.doLogin(token, tokenRefresh)
          this.error = false
          this.$router.push('/site')
        }
        else {
          API.appScope.doLogout();
          this.error = 'Unauthorized: incorrect username or password'
        }
      })
      .catch(err => {
        API.appScope.doLogout();
        this.error = 'Unauthorized: incorrect username or password'
        console.log('Login:', err)
      })
    },
  }
};
</script>

<style scoped>
.auth-error {
  margin-top: -20px;
  padding-left: 33px;
  font-size: 12px;
  letter-spacing: 0;
}
.v-btn.success {
  margin-top: 16px;
}
</style>
