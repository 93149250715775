import axios from 'axios'
import router from "./router/router"
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import { eventBus } from './main';


function setupSignalRConnection() {

  const retryTimes = [0, 1000, 5000, 10000, 20000, 30000, 60000];

  const monitoringHubConnection = new HubConnectionBuilder()
    .withUrl("MonitoringEvent", {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        return appScope.getToken();
      }
    })
    .configureLogging(LogLevel.Error)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: context => {
        const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
        console.log('Connnection not established. Reconnecting after', retryTimes[index]/1000, 'seconds');
        return retryTimes[index];
      }
    })
    .build();

  return monitoringHubConnection;
}

// Call the setup function when the page initially loads
let connection = setupSignalRConnection();

// Check for a page reload and reinitialize the connection
window.addEventListener("beforeunload", () => {
  stopMonitoringHubConnection();
});

window.addEventListener("load", () => {
  if (appScope.isLoggedIn()) {
    startMonitoringHubConnection();
  }
});

let startMonitoringHubConnection = () => {
  if (connection) {
    stopMonitoringHubConnection();
    // Create a new connection instance
    connection = setupSignalRConnection(); 
  }

  connection.on("onConnect", function (message) {
    console.log('monitoringHubConnection started:', message)
  });

  connection.on("onCameraStateChangedEventReceive", function (message) {
    eventBus.$emit('onCameraStateChangedEvent', { state: message });
  });
  connection.on("onControllerStateChangedEventReceive", function (message) {
    eventBus.$emit('onControllerStateChangedEvent', { state: message });
  });
  connection.on("onSiteChangedEvent", function (message) {
    eventBus.$emit('onSiteChangedEvent', { data: message });
  });
  connection.on("onSiteAddedEvent", function (message) {
    eventBus.$emit('onSiteAddedEvent', { data: message });
  });
  connection.on("onSiteDeletedEvent", function (message) {
    eventBus.$emit('onSiteDeletedEvent', { data: message });
  });
  
  connection.onreconnecting(error => {
    console.log(`Connection lost due to error "${error}". Reconnecting...`);
  });

  connection.onreconnected(connectionId => {
    console.log(`Connection reestablished. Connected with connectionId "${connectionId}".`)
  });

  connection.start().catch(function(err) {return console.error('MyERROR:',err.toString());});
}

let stopMonitoringHubConnection = () => {
  connection.stop()
      .then(function() {console.log('monitoringHubConnection: closed');})
      .catch(function(err) {return console.error(err.toString());})
}

function parseJwt (token) {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } 
  catch {
    return null
  }
}

var appConst =
{
  ROLE_ADMIN : 'Admin',
  TOKEN_NAME : 'accessToken',
  TOKEN_REFRESH_NAME : 'refreshToken',
  TOKEN_EXPIRY : 300000, // 5 min

  LIVE_STATUS_UNKNOWN : {'type':  null,  'code': null, 'message' : 'Status unknown'},
  LIVE_STATUS_GOOD    : {'type': 'good', 'code': 0,    'message' : 'Online'},
  LIVE_STATUS_FAIL    : {'type': 'fail', 'code': 100,  'message' : 'Offline'},
  LIVE_STATUS_EVENT_TIMEOUT  : {'type': 'warn', 'code': 10, 'message' : 'Event Timeout'},
  LIVE_STATUS_LOW_CONFIDENCE : {'type': 'warn', 'code': 11, 'message' : 'Low Confidence'},
};

var appScope = new class AppScope
{ 
  // Token cache functions
  theToken        = null;
  theRefreshToken = null;
  theCurrUser     = null;
  theCurrUserRole = null;
  theAlertsCount  = 0;

  refreshTokenTimeoutId = null;

  fillByToken(newToken)
  {
    if (newToken == null) {
      this.theCurrUser     = null;
      this.theCurrUserRole = null;  
    } else {
      let payload = parseJwt(newToken);
      this.theCurrUser = payload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
      this.theCurrUserRole = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];    
    }
  }

  constructor()
  {
    this.theToken = window.localStorage.getItem(appConst.TOKEN_NAME);
    this.theRefreshToken = window.localStorage.getItem(appConst.TOKEN_REFRESH_NAME);
    this.fillByToken(this.theToken);
    this.theAlertsCount = 0;
  }

  setToken(newToken)
  {
    this.theToken = newToken;
    this.fillByToken(this.theToken);
  }

  getToken()
  {
    return this.theToken;
  }

  setRefreshToken(newRefreshToken)
  {
    this.theRefreshToken = newRefreshToken;
  }

  getRefreshToken()
  {
    return this.theRefreshToken;
  }

  refreshToken() {
    HTTP_AUTH.post('/refresh_token', {
      'refreshToken': appScope.getRefreshToken(),
      'accessToken': appScope.getToken()
    })
    .then((r) => { 
      const newAccessToken  = r.data.accessToken;
      const newRefreshToken = r.data.refreshToken;

      window.localStorage.setItem(appConst.TOKEN_NAME, newAccessToken);
      window.localStorage.setItem(appConst.TOKEN_REFRESH_NAME, newRefreshToken);
 
      this.setToken(newAccessToken);
      this.setRefreshToken(newRefreshToken);

      clearTimeout(this.refreshTokenTimeoutId);

      this.scheduleTokenRefresh();
    })
    .catch((error) => {
      console.log('refreshToken:', error)
    })
  }
  
  scheduleTokenRefresh() {
    this.refreshTokenTimeoutId = setTimeout(() => {
      this.refreshToken();
    }, appConst.TOKEN_EXPIRY);
  }

  // Login/logout
  doLogin(newToken, newRefreshToken)
  {
    window.localStorage.setItem(appConst.TOKEN_NAME, newToken);
    window.localStorage.setItem(appConst.TOKEN_REFRESH_NAME, newRefreshToken);

    // Schedule the next token refresh
    this.scheduleTokenRefresh();

    this.setToken(newToken);
    this.setRefreshToken(newRefreshToken);

    eventBus.$emit('isLoginEvent', { login: true });

    startMonitoringHubConnection();
  }

  doLogout()
  {
    if (!appScope.isLoggedIn()) return;

    HTTP_AUTH.post('/logout')
    .then(() => { 
      clearTimeout(this.refreshTokenTimeoutId);
    })
    .catch((error) => {
      console.log('Logout:', error)
    })
    .finally(() => {
      window.localStorage.removeItem(appConst.TOKEN_NAME);
      window.localStorage.removeItem(appConst.TOKEN_REFRESH_NAME);
      this.setToken(null);
      this.setRefreshToken(null);

      eventBus.$emit('isLoginEvent', { login: false });
    })

    stopMonitoringHubConnection();        
    router.push('/').catch(error => {
      // on abort only ignore NavigationDuplicated error
      if (error.name !== 'NavigationDuplicated') {
        throw(error); 
      }
    })
  }

  isLoggedIn()
  {
    return this.theToken != null;
  }

  splitCoordinates(location) {
    if (!location) return { lat: 0, lng: 0 }
    const [lat, lng] = location.split(',').map(parseFloat);
    if (!lat || !lng) return { lat: 0, lng: 0 }
    return { lat: parseFloat(lat.toFixed(4)), lng: parseFloat(lng.toFixed(4)) };
  }
  
  joinCoordinates(lat, lon) {
    return `${lat},${lon}`;
  }
  
  validateLocation(location) {
    const pattern = /^-?([1-8]?[1-9]|[1-9]0)\.\d{1,6}?,\s*-?([1-8]?[1-9]|[1-9]0)\.\d{1,6}?$/;

    if (!location) return false;
    if (!location.match(pattern)) return false;
    return true;
  }

  getCurrentTime() {
    let now     = new Date();
    let hours   = this.format(now.getHours());
    let minutes = this.format(now.getMinutes());
    let seconds = this.format(now.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
  }

  format(value) {
    return value.toString().padStart(2, '0');
  }

  findParentWithClassName(node, className) {
    if (className == null) { return null; }
    while(node != null) {
      if (node.classList.contains(className)) { return node; }
      node = node.parentNode;
    }
    return null;
  }

  findTreeItemById(tree, id) {
    // Recursive function that traverses the entire tree, including its children and their children
    for (const item of tree) {
      if (item.id === id) {
        return item;
      }

      if (item.children && item.children.length > 0) {
        const result = this.findTreeItemById(item.children, id);
        if (result) {
          return result;
        }
      }
    }
    return null; // such id not found in the tree
  }

};

//if (appScope.isLoggedIn()) { startMonitoringHubConnection(); }

window.appScope = appScope;

const HTTP = axios.create({
  baseURL: '/api/configuration',
  withCredentials: true
})
const HTTP_AUTH = axios.create({
  baseURL: '/api/identity',
  withCredentials: true
})
const HTTP_MONITORING = axios.create({
  baseURL: '/api/monitoring',
  withCredentials: true
})

HTTP.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken  = refreshResponse.data.accessToken;
        const newRefreshToken = refreshResponse.data.refreshToken;

        appScope.doLogin(newAccessToken, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token ', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);

HTTP_AUTH.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry && originalRequest.url != '/logout') {
      originalRequest._retry = true;

      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken = refreshResponse.data.accessToken
        const newRefreshToken = refreshResponse.data.refreshToken
        appScope.doLogin(newAccessToken, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token:', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);
HTTP_MONITORING.interceptors.response.use(
  (response) => { return response; },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResponse = await HTTP_AUTH.post('/refresh_token', {
          'refreshToken': appScope.getRefreshToken(),
          'accessToken': appScope.getToken()
        })
        const newAccessToken = refreshResponse.data.accessToken
        const newRefreshToken = refreshResponse.data.refreshToken

        appScope.doLogin(newAccessToken, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return await axios(originalRequest)
      } 
      catch (refreshError) {
        console.error('Error refreshing token:', refreshError.code)
        appScope.doLogout()
        return await Promise.reject(refreshError)
      }
    }
    return Promise.reject(error);
  }
);
HTTP_AUTH.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);

HTTP_MONITORING.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);

HTTP.interceptors.request.use(
  (config) => {
    if (appScope.isLoggedIn()) { config.headers['Authorization'] = `Bearer ${appScope.getToken()}`; }
    return config;
  }, 
  (error) => { return Promise.reject(error); }
);

window.appScope = appScope;

var API = {

// -------------- Site
// obj => { "site_id": 0, 
//  "site_id": "string",
//  "site_name": "string",
//  "site_location": "string", (lat,lon calculated by camera\lane or by manual)
//  "site_desc": "string",
//  "site_token": "string"
// }
 
  appConst,
  appScope,

  getSitesCount () {
    return HTTP.get('/sites/count')
  },
  getSites () {
    return HTTP.get('/sites')
  },
  getSite (id) {
    return HTTP.get('/sites/' + id)
  },
  addSite (obj) {
    return HTTP.post('/sites', obj)
  },
  editSite (obj) {
    return HTTP.put('/sites', obj)
  },
  deleteSite (id) {
    // responce => { "result": "string", "message": "string" }
    return HTTP.delete('/sites/' + id)
  },
  createToken (site_id) {
    return HTTP.get('/sites/generate_token/' + site_id)
  },
  downloadJson (site_id) {
    return HTTP.get('/sites/token/download/' + site_id, { responseType: 'blob' })
  },

  // -------------- Controllers
  // obj => { 
  //  "controller_id": "string",
  //  "controller_name": "string",
  //  "controller_location": "string",
  //  "controller_ip_addr": "string",
  //  "controller_ip_port": 0,
  //  "controller_desc": "string",
  //  "site_id": "string"
  // }
 
  getControllersCount () {
    return HTTP.get('/controllers/count')
  },
  getControllers () {
    return HTTP.get('/controllers')
  },
  getController (id) {
    return HTTP.get('/controllers/' + id)
  },
  getControllersBySite (site_id) {
    return HTTP.get('/controllers/by_site/' + site_id)
  },
  addController (obj) {
    return HTTP.post('/controllers', obj)
  },
  editController (obj) {
    return HTTP.put('/controllers', obj)
  },
  deleteController (id) {
    return HTTP.delete('/controllers/' + id)
  },


  // -------------- Lane
  // obj => { 
  //  "lane_id": "string",
  //  "lane_name": "string",
  //  "lane_location": "string",
  //  "lane_desc": "string",
  //  "lane_external_id": "string",
  //  "controller_id": "string"
  // }

  getLanesCount () {
    return HTTP.get('/lanes/count')
  },
  getLanes () {
    return HTTP.get('/lanes')
  },
  getLane (id) {
    return HTTP.get('/lane/' + id)
  },
  getLanesBySite (site_id) {
    return HTTP.get('/lanes/by_site/' + site_id)
  },
  getLanesByController (controller_id) {
    return HTTP.get('/lanes/by_controller/' + controller_id)
  },
  addLane (obj) {
    return HTTP.post('/lanes', obj)
  },
  editLane (obj) {
    return HTTP.put('/lanes', obj)
  },
  deleteLane (id) {
    return HTTP.delete('/lanes/' + id)
  },

  // --------------- Camera
  // obj => {
  //  "camera_id": "string",
  //  "camera_name": "string",
  //  "camera_location": "string",
  //  "camera_desc": "string",
  //  "lane_id": "string",
  //  "controller_id": "string"  
  // }

  getCamerasCount () {
    return HTTP.get('/cameras/count')
  },
  getCameras () {
    return HTTP.get('/cameras')
  },
  getCamera (id) {
    return HTTP.get('/cameras/' + id)
  },
  getCamerasBySite (site_id) {
    return HTTP.get('/cameras/by_site/' + site_id)
  },
  getCamerasByLane (lane_id) {
    return HTTP.get('/cameras/by_lane/' + lane_id)
  },
  getCamerasByController (controller_id) {
    return HTTP.get('/cameras/by_controller/' + controller_id)
  },
  addCamera (obj) {
    return HTTP.post('/cameras', obj)
  },
  editCamera (obj) {
    return HTTP.put('/cameras', obj)
  },
  deleteCamera (id) {
    return HTTP.delete('/cameras/' + id)
  },
  //----------------- AlertMail
  // obj => {
  //  "mail_server_name": "string",
  //  "mail_user_name": "string",
  //  "mail_password": "string",
  //  "from_mail_address": "string",
  //  "enable_ssl": true,
  //  "mail_port": 0
  // }
   getAlertMail () {
    return HTTP.get('/setting/mail')
   },
   editAlertMail (obj) {
    return HTTP.put('/setting/mail', obj)
   },

  // --------------- Monitoring REST API
  //
   getStateBySite (site_id) {
    return HTTP_MONITORING.get('/map_state/' + site_id)
   },
   getStateSites () {
    return HTTP_MONITORING.get('/sites_state')
   },
   getStateControllers () {
    return HTTP_MONITORING.get('/controllers_state')
   },
   getStateLanes () {
    return HTTP_MONITORING.get('/lanes_state')
   },
   getStateCameras () {
    return HTTP_MONITORING.get('/cameras_state')
   },

  // --------------- Identity
  //  obj => { "id":0, "user":"string", "password":"string", "role":1 }
  //
  login (obj) {
    // obj => { "user":"string", "password":"string" }
    return HTTP_AUTH.post('/login', obj)
  },
  getUsers () {
    return HTTP_AUTH.get('/users')
  },
  addUser (obj) {
    return HTTP_AUTH.post('/users', obj)
  },
  editUser (obj) {
    return HTTP_AUTH.put('/users', obj)
  },
  getUserById (id) {
    return HTTP_AUTH.get('/users/' + id)
  },
  deleteUser (id) {
    return HTTP_AUTH.delete('/users/' + id)
  },
  changePassword (obj) {
    return HTTP_AUTH.post('/change_password', obj)
  },
  resetPassword (obj) {
    return HTTP_AUTH.post('/reset_password', obj)
  },

  // extra tree functions
  
  async getSitesFull() {
    let sites = [];
    let states = [];
    const response = await API.getSites();
    if (response.data.isOk) {
      sites = response.data.data
      return API.getStateSites()
      .then((r) => {
        if (r.data.isOk) {
          states = r.data.data;
          sites = sites.map(site => {
            const state = states.find(s => s.site_id === site.site_id)?.is_online || false;
            return {
              ...site , 
              type : 'site',
              location : site.site_location,
              state: state,
              status: state ? appConst.LIVE_STATUS_GOOD.type : appConst.LIVE_STATUS_FAIL.type
            }
          });
        }
        else { throw new Error('Error in getSitesState'); }

        return sites.sort(function (a, b) {
          return a.site_name.localeCompare(b.site_name);
        });
      })
    }
    else { throw new Error('Error in getSites'); }
  },

  async getControllersFull() {
    let controllers = [];
    let states = [];
    const response = await API.getControllers();
    if (response.data.isOk) {
      controllers = response.data.data;
      return API.getStateControllers()
      .then((r) => {
        if (r.data.isOk) {
          states = r.data.data;
          controllers = controllers.map(controller => {
            let controller_in_states = states.find(s => s.controller_id === controller.controller_id);
            let state   = controller_in_states?.is_online || false;
            let warning = controller_in_states?.controller_has_warning || false;
            return {
              ...controller,
              location: controller.controller_location,
              state: state,
              status: state ? appConst.LIVE_STATUS_GOOD.type : appConst.LIVE_STATUS_FAIL.type,
              warning: warning
            };
          });
        }
        else { throw new Error('Error in getStateControllers'); }
        return controllers;
      });
    }
    else { throw new Error('Error in getControllers'); }
  },

  async getLanesFull() {
    let lanes = [];
    let states = [];
    const response = await API.getLanes();
    if (response.data.isOk) {
      lanes = response.data.data;
      return API.getStateLanes()
      .then((r) => {
        if (r.data.isOk) {
          states = r.data.data;
          lanes = lanes.map(lane => {
            const state = states.find(s => s.lane_id === lane.lane_id)?.is_online || false;
            return {
              ...lane, 
              location: lane.lane_location,
              state: state,
              status: state ? appConst.LIVE_STATUS_GOOD.type : appConst.LIVE_STATUS_FAIL.type
            }
          });
        }
        else { throw new Error('Error in getStateLanes'); }
        return lanes;
      });
    }
    else { throw new Error('Error in getLanes'); }
  },

  async getCamerasFull() {
    let cameras = [];
    let states = [];
    const response = await API.getCameras();
    if (response.data.isOk) {
      cameras = response.data.data;
      return API.getStateCameras()
      .then((r) => {
        if (r.data.isOk) {
          states = r.data.data;
          cameras = cameras.map(camera => {
            const state = states.find(s => s.camera_id === camera.camera_id)?.is_online || false;
            return {
              ...camera,
              location: camera.camera_location,
              state: state,
              status: state ? appConst.LIVE_STATUS_GOOD.type : appConst.LIVE_STATUS_FAIL.type
            };
          });
        }
        else { throw new Error('Error in getCamerasState'); }
        return cameras;
      });
    }
    else { throw new Error('Error in getCameras'); }
  },

  makeDevicesOffAlerts(cameras, controllers, sites) {
    let event_time = API.appScope.getCurrentTime();
  
    let cameras_off = cameras.map(camera => {
      if (!camera.state) {
        return {
          camera_id:   camera.camera_id,
          camera_name: camera.camera_name,
          camera_ip:   camera.camera_ip,
          site_id:   camera.site_id,
          site_name: camera.site_name,
          controller_id:   camera.controller_id,
          controller_name: camera.controller_name,
          lane_id:   camera.lane_id,
          lane_name: camera.lane_name,
          type: 'Camera',
          event_time: event_time
        }
      }
    }).filter(el => el != null);
  
    let controllers_off = controllers.map(controller => {
      if (!controller.state) {
        let site_name = sites.find(s => s.site_id == controller.site_id).site_name
        return {
          site_id: controller.site_id,
          site_name: site_name,
          controller_id:   controller.controller_id,
          controller_name: controller.controller_name,
          controller_ip:   controller.controller_ip_addr,
          type: 'Controller',
          event_time: event_time
        }
      }
    }).filter(el => el != null);
  
    const filteredCameras = cameras_off.filter(cam => {
      // Check if cam.controller_id exists in controllers_off - if so, remove it (filter the camera_off)
      return !controllers_off.some(c => c.controller_id === cam.controller_id);
    });
  
    var devicesOff = [...controllers_off, ...filteredCameras];
  
    return devicesOff;
  },

  async getDevicesOffAlerts() {
    if (!appScope.isLoggedIn()) {return [];}
    try {
      // run getSites(), getControllers(), getLanes(), getCameras() in parallel
      let sites = [];
      let controllers = [];
    //let lanes = [];
      let cameras = [];

      await Promise.all([
        API.getSitesFull().then((r) => { sites = r; }).catch((error) => { throw error; }),
        API.getControllersFull().then((r) => { controllers = r; }).catch((error) => { throw error; }),
      //API.getLanesFull().then((r) => { lanes = r; }).catch((error) => { throw error; }),
        API.getCamerasFull().then((r) => { cameras = r; }).catch((error) => { throw error; })
      ]); 

      return API.makeDevicesOffAlerts(cameras, controllers, sites);
    } 
    catch (error) { console.error('Error in getDevicesOffAlerts:', error); throw error; }
  },
};

export default API;