import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import { addOrUpdateAlerts, copyCommonProperties } from './storeHelpers';
import API from './../api';

const STATUS_GOOD = API.appConst.LIVE_STATUS_GOOD.type;
const STATUS_FAIL = API.appConst.LIVE_STATUS_FAIL.type;

const store = new Vuex.Store({
  state: {
    sites: [],
    controllers: [],
    lanes: [],
    cameras: [],
    tree: [],
    treeFiltered: [],
    locationMarkers: [],
    selectedStatusTree: -1,
    alertCount: 0,
    devicesOff: [],
    reload: false,
  },
  actions: {
    updateStates({ commit }, site_id) {
      //console.log('onSiteChangedEvent')
        API.getStateBySite(site_id).then((response) => {
          if (response.data.isOk) {
            commit("updateStatesBySite", response.data.data)
            commit("replaceDevicesOffAlertsBySyte", { data: response.data.data, site_id })
          }
          else {
            console.log(response.error)
          }
        }).catch((error) => { console.log(error) })
    },
  },
  mutations: {
    resetState(state) {
      // Set state variables to their initial values
      state.sites = [];
      state.controllers = [];
      state.lanes = [];
      state.cameras = [];
      state.tree = [];
      state.treeFiltered = [];
      state.alertCount = 0;
      state.devicesOff = [];
      state.reload = false; 
    },
    
    updateStatesBySite(state, data) {
      let items = data.items;

      // update tree
      items.map(item => {
        let itemToUpdate  = API.appScope.findTreeItemById(state.tree, item.id);
        if (itemToUpdate) {
          itemToUpdate.detail.state  = item.is_online
          itemToUpdate.detail.status = (item.is_online ? STATUS_GOOD : STATUS_FAIL)
          itemToUpdate.status = (item.is_online ? STATUS_GOOD : STATUS_FAIL)
        }
        itemToUpdate.warning = (item.type ==='controller' && item.has_warning && item.is_online)     
        
        // update datasets cameras && lanes
        if (item.type === 'camera') {
          let cameraToUpdate = state.cameras.find(camera => camera.camera_id === item.id)
          if (cameraToUpdate) { 
            cameraToUpdate.status = (item.is_online ? STATUS_GOOD : STATUS_FAIL)
          }
        }
        if (item.type === 'lane') {
          let laneToUpdate = state.lanes.find(lane => lane.lane_id === item.id)
          if (laneToUpdate) { 
            laneToUpdate.status = (item.is_online ? STATUS_GOOD : STATUS_FAIL) 
          }
        }
      });

      // update treeFiltered
      this.commit('filterTreeByStatus')
    },

    replaceDevicesOffAlertsBySyte(state, payload) {
      const { data, site_id } = payload;

      let devicesOffExist  = state.devicesOff.filter(d=> d.site_id !== site_id)
      let devicesOffBySite = []

      let event_time = API.appScope.getCurrentTime();

      let cameras_off = data.items.map(item => {
        if (item.type === 'camera' && !item.is_online) {
          let camera = state.cameras.find(c => c.camera_id === item.id)
          if (camera) {
            return {
              camera_id: item.id,
              camera_name: item.name,
              camera_ip: item.camera_ip,
              site_id: site_id,
              site_name: item.site_name,
              controller_id: camera.controller_id,
              controller_name: item.controller_name,
              lane_id: camera.lane_id,
              lane_name: camera.lane_name,
              type: 'Camera',
              event_time: event_time
            } 
          }
        }
      }).filter(el => el != null);

      let controllers_off = data.items.map(item => {
        if (item.type === 'controller' && !item.is_online) {
          return {
            site_id: site_id,
            site_name: item.site_name,
            controller_id: item.id,
            controller_name: item.controller_name,
            controller_ip: item.controller_ip_addr,
            type: 'Controller',
            event_time: event_time
          }
        }
      }).filter(el => el != null);

      const filteredCameras = cameras_off.filter(cam => {
        // Check if cam.controller_id exists in controllers_off - if so, remove it (filter the camera_off)
        return !controllers_off.some(c => c.controller_id === cam.controller_id);
      });
    
      devicesOffBySite = [...controllers_off, ...filteredCameras]

      state.devicesOff = [...devicesOffBySite, ...devicesOffExist]
      state.alertCount = state.devicesOff.length
    },

    filterTreeByStatus(state) {
      // console.log('STORE: filterTreeByStatus')
      if (state.selectedStatusTree != -1) {
        state.treeFiltered = state.tree.filter(item => item.status === state.selectedStatusTree)
      }
      else {
        state.treeFiltered = state.tree
      }
      // console.log('STORE: TREE_FILTERED:',state.treeFiltered);
      state.reload = true;
    },

    deleteControllersBySiteId(state, id) {
      for (let i = state.controllers.length - 1; i >= 0; i--) {
        if (state.controllers[i].site_id === id) {
          state.controllers.splice(i, 1);
        }
      }
    },
    deleteLanesBySiteId(state, id) {
      for (let i = state.lanes.length - 1; i >= 0; i--) {
        if (state.lanes[i].site_id === id) {
          state.lanes.splice(i, 1);
        }
      }
    },
    deleteCamerasBySiteId(state, id) {
      for (let i = state.cameras.length - 1; i >= 0; i--) {
        if (state.cameras[i].site_id === id) {
          state.cameras.splice(i, 1);
        }
      }
    },

    deleteLanesByControllerId(state, id) {
      for (let i = state.lanes.length - 1; i >= 0; i--) {
        if (state.lanes[i].controller_id === id) {
          state.lanes.splice(i, 1);
        }
      }
    },
    deleteCamerasByControllerId(state, id) {
      for (let i = state.cameras.length - 1; i >= 0; i--) {
        if (state.cameras[i].controller_id === id) {
          state.cameras.splice(i, 1);
        }
      }
    },


    deleteSiteById(state, id) {
      // DELETE FROM TREE AND ALERTS
      let index = state.tree.findIndex(el => el.id === id);
      if (index !== -1) {
        // remove from devicesOff all devices by this site_id
        for (let i = state.devicesOff.length - 1; i >= 0; i--) {
          if (state.devicesOff[i].site_id === id) {
            state.devicesOff.splice(i, 1);
          }
        }
        state.alertCount = state.devicesOff.length

        state.tree.splice(index, 1);
        state.treeFiltered = [...state.tree];
      }
      
      // --------------------------------- DELETE FROM MAP MARKERS     
      index = state.locationMarkers.findIndex(item => item.id === id);
      if (index != -1) {
       state.locationMarkers.splice(index, 1);
      }

      // DELETE FROM ALL DATASETS (sites, controllers, lanes, cameras)
      index = state.sites.findIndex(el => el.site_id === id);
      if (index != -1) {
       state.sites.splice(index, 1);
      }
      this.commit('deleteControllersBySiteId', id)
      this.commit('deleteLanesBySiteId', id)
      this.commit('deleteCamerasBySiteId', id)   
    },

    deleteControllerById(state, id) 
    {
      // DELETE FROM TREE AND ALERTS
      for (let i = 0; i < state.tree.length; i++) {
        const site = state.tree[i];
        if (site.children) {
          const childIndex = site.children.findIndex(c => c.id === id);
          if (childIndex !== -1) {
            // remove from devicesOff all devices by this controller_id
            for (let i = state.devicesOff.length - 1; i >= 0; i--) {
              if (state.devicesOff[i].controller_id === id) {
                state.devicesOff.splice(i, 1);
              }
            }
            state.alertCount = state.devicesOff.length

            site.children.splice(childIndex, 1);
            state.treeFiltered = [...state.tree];

            break; 
          }
        }
      }

      // DELETE FROM ALL DATASETS (controllers, lanes, cameras)
      let index = state.controllers.findIndex(el => el.controller_id === id);
      if (index != -1) {
        state.controllers.splice(index, 1);
      }
      this.commit('deleteLanesByControllerId', id)
      this.commit('deleteCamerasByControllerId', id)     
    },

    deleteLaneById(state, id) 
    {
      let index = state.lanes.findIndex(el => el.lane_id === id);
      state.lanes.splice(index, 1);
    },

    deleteCameraById(state, id) 
    {
      let index = state.cameras.findIndex(el => el.camera_id === id);
      state.cameras.splice(index, 1);
    },

    //------------------------------------------------ WS EVENTS ------------------------------------
    onSiteAddedEvent(state, event) {
      let e = event.data
      let site = {
        site_id: e.site_id,
        site_name: e.site_name,
        site_location: e.site_location,
        site_desc: e.site_desc,
        location: e.site_location,
        site_token: '',
        state: true,
        status: STATUS_GOOD,
      }

      let new_site = {
        id: e.site_id,
        name: e.site_name,
        detail: site,
        type: 'site',
        status: site.status,
      }

      let index = state.tree.findIndex(obj => obj.id === e.site_id);      
      if (index === -1) {
        // if there is no response from the rest API yet, then the site needs to add a tree
        state.tree.push(new_site)
      }

      this.commit('filterTreeByStatus'); 

      // --------------------------------- ADD SITE TO MAP MARKERS     
      index = state.locationMarkers.findIndex(item => item.id === e.site_id);
      if (index === -1) {
        new_site.position = API.appScope.splitCoordinates(site.site_location)        
        state.locationMarkers.push(new_site);
      }

      state.reload = true;
    },

    onSiteDeletedEvent(state, event) {
      let e = event.data

      this.commit('deleteSiteById', e.site_id)
      this.commit('filterTreeByStatus'); 
     
      state.reload = true;
    },

    onSiteChangedEvent(state, event) {
      let e           = event.data

      let controllers = e.site_controllers
      let lanes       = e.site_lanes
      let cameras     = e.site_cameras

      let siteToUpdate  = API.appScope.findTreeItemById(state.tree, e.site_id);
     
      let site = {
        site_id: e.site_id,
        site_name: e.site_name,
        site_location: e.site_location,
        site_desc: e.site_desc,
        location: e.site_location,
        site_token: e.site_token,
        state: null,
        status: null
      }

      let new_site = {
        id: e.site_id,
        name: e.site_name,
        detail: site,
        type: 'site',
        status: null,
        children: controllers.map(controller => {

          let controllerLanes = lanes.filter(lane => lane.controller_id === controller.controller_id);
          controller.location = controller.controller_location ? controller.controller_location : site.location
          controller.state    = null
          controller.status   = null

          return {
            id: controller.controller_id,
            name: controller.controller_name,
            detail: controller,
            type: 'controller',
            status: null,
            warning: null,
            children: controllerLanes.map(lane => {
              let laneCameras = cameras.filter(camera => camera.lane_id === lane.lane_id);             
              lane.location = lane.lane_location ? lane.lane_location : controller.location
              lane.state    = null
              lane.status   = null
   
              return {
                id: lane.lane_id,
                name: lane.lane_name,
                detail: lane,
                type: 'lane',
                status: null,
                children: laneCameras.map(camera => {
                  camera.location = camera.camera_location ? camera.camera_location : lane.location
                  camera.state    = null
                  camera.status   = null

                  return {
                    id: camera.camera_id,
                    name: camera.camera_name,
                    detail: camera,
                    type: 'camera',
                    status: null
                  }
                }),
              };
            }),
          };
        })
      }

      // add unassigned cameras (lane_id === null) to their controller
      const unassignedCameras = cameras.filter(camera => !camera.lane_id)

      for (const treeController of new_site.children) {
         const uc = unassignedCameras.filter(camera => camera.controller_id === treeController.id);
         let laneToUpdate = API.appScope.findTreeItemById(siteToUpdate.children, 0);

         if (uc.length > 0) {
           treeController.children.push({
             id: 0,
             name: laneToUpdate ? laneToUpdate.name : 'Cameras out of lanes',
             detail: {},
             type: 'lane',
             status: laneToUpdate ? laneToUpdate.status : STATUS_GOOD,
             children: uc.map(camera => {
               camera.location = camera.camera_location ? camera.camera_location : treeController.detail.location
               camera.state    = null
               camera.status   = null

               return {
                 id: camera.camera_id,
                 name: camera.camera_name,
                 detail: camera,
                 type: 'camera',
                 status: camera.status
               }
             })
           });
         }
      }

      const index = state.tree.findIndex(obj => obj.id === e.site_id);
    
      if (index !== -1) {
        // replace the old site with a new one
        state.tree.splice(index, 1);
        state.tree.splice(index, 0, new_site);
      }
     
      // update DATASETS (cameras && lanes)
      this.commit('updateCamerasBySiteChangedEvent', cameras)
      this.commit('updateLanesBySiteChangedEvent', lanes)

      // --------------------------------- UPDATE MAP MARKERS     
      let markerToUpdate = state.locationMarkers.find(item => item.id === e.site_id);
      if (markerToUpdate) {
        copyCommonProperties(e, markerToUpdate)
        markerToUpdate.position = API.appScope.splitCoordinates(e.site_location)        
      }

      // request states from API
      this.dispatch('updateStates', e.site_id);
     
      state.reload = true;
    },

    onCameraStateUpdatedEvent(state, event) 
    {
      let e = event.state;
      if (!e.controller_name) return;

      // --------------------------------- UPDATE TREE
      if (!e.lane_id) e.lane_id = 0; // lineId for unassigned cameras (event lane_id === null)

      let siteToUpdate = API.appScope.findTreeItemById(state.tree, e.site_id);

      if (siteToUpdate) {
        siteToUpdate.status       = e.site_is_online ? STATUS_GOOD : STATUS_FAIL;
        siteToUpdate.detail.state = e.site_is_online

        let controllerToUpdate = API.appScope.findTreeItemById(siteToUpdate.children, e.controller_id);
        if (controllerToUpdate) {
          controllerToUpdate.warning = (e.controller_has_warning && e.controller_is_online)
          controllerToUpdate.status  = e.controller_is_online ? STATUS_GOOD : STATUS_FAIL;
          controllerToUpdate.detail.state = e.controller_is_online

          let laneToUpdate = API.appScope.findTreeItemById(controllerToUpdate.children, e.lane_id);
          if (laneToUpdate) { 
            laneToUpdate.status       = e.lane_is_online ? STATUS_GOOD : (!e.lane_is_online ? STATUS_FAIL : null);
            laneToUpdate.detail.state = e.lane_is_online ? e.lane_is_online : null;

            let cameraToUpdate  = API.appScope.findTreeItemById(laneToUpdate.children, e.camera_id);
            if (cameraToUpdate) { 
              cameraToUpdate.status       = e.camera_is_online ? STATUS_GOOD : STATUS_FAIL; 
              cameraToUpdate.detail.state = e.camera_is_online
            }
          }
        }
      }
      // UPDATE treeFiltered
      this.commit('filterTreeByStatus');

      // ---------------------------------  UPDATE STATUSES FOR DATASETS
      // TODO : need to update ...ToUpdate.state also
      siteToUpdate = state.sites.find(item => item.site_id === e.site_id);
      if (siteToUpdate) { siteToUpdate.status = e.site_is_online ? STATUS_GOOD : STATUS_FAIL; }

      let controllerToUpdate = state.controllers.find(item => item.controller_id === e.controller_id);
      if (controllerToUpdate) { controllerToUpdate.status = e.controller_is_online ? STATUS_GOOD : STATUS_FAIL; }

      let laneToUpdate = state.lanes.find(item => item.lane_id === e.lane_id);
      if (laneToUpdate) { laneToUpdate.status = e.lane_is_online ? STATUS_GOOD : STATUS_FAIL; }    

      let cameraToUpdate = state.cameras.find(item => item.camera_id === e.camera_id);
      if (cameraToUpdate) { cameraToUpdate.status = e.camera_is_online ? STATUS_GOOD : STATUS_FAIL; }
      
      // ---------------------------------  UPDATE STATUSES FOR ALERTS
      if (e.camera_is_online) {
        // camera is online => remove it from alerts.     
        for (let i = state.devicesOff.length - 1; i >= 0; i--) {
          if (state.devicesOff[i].camera_id === e.camera_id) {
            state.devicesOff.splice(i, 1);
            break;
          }
        }

        // and remove parent controller from alerts.
        for (let i = state.devicesOff.length - 1; i >= 0; i--) {
          if (state.devicesOff[i].type === 'Controller' && state.devicesOff[i].controller_id === e.controller_id) {
            state.devicesOff.splice(i, 1);
            break;
          }
        }
        state.devicesOff = state.devicesOff.sort(function (a, b) {
          return b.event_time.localeCompare(a.event_time);
        });
}
      else {
        // camera is offline => add it to camera_alerts or update (if already exist).
        let cameraToUpdate = { ...e }
        cameraToUpdate.event_time = API.appScope.getCurrentTime()
        cameraToUpdate.type       = 'Camera'

        addOrUpdateAlerts(state, cameraToUpdate, 'camera_id');
      }
      state.alertCount = state.devicesOff.length

      // --------------------------------- UPDATE MAP MARKERS
      let markerToUpdate = state.locationMarkers.find(item => item.id === e.site_id);
      if (markerToUpdate) {
        markerToUpdate.status = e.site_is_online ? STATUS_GOOD : STATUS_FAIL;
      }    
    },

    updateCamerasBySiteChangedEvent(state, camerasFromEvent)
    {
      for (const item of camerasFromEvent) {
        let camera = state.cameras.find(c => c.camera_id === item.camera_id);
        if (camera) { 
          copyCommonProperties(item, camera) 
        }
      }
    },

    updateLanesBySiteChangedEvent(state, lanesFromEvent)
    {
      for (const item of lanesFromEvent) {
        let lane = state.lanes.find(l => l.lane_id === item.lane_id);
        if (lane) { 
          copyCommonProperties(item, lane) 
        }
      }
    },

    onControllerStateUpdatedEvent(state, event) {
      let e = event.state;
      if (!e.site_name) return;
      
      // --------------------------------- UPDATE TREE
      let controllerToUpdate = API.appScope.findTreeItemById(state.tree, e.controller_id);
      if (controllerToUpdate) {
        controllerToUpdate.status  = e.is_online ? STATUS_GOOD : STATUS_FAIL;
        controllerToUpdate.detail.state = e.controller_is_online
        controllerToUpdate.warning = (e.controller_has_warning && e.is_online)
      }

      // --------------------------------- UPDATE STATUSES FOR DATASET & ALERTS
      controllerToUpdate = state.controllers.find(item => item.controller_id === e.controller_id);
      if (controllerToUpdate) { controllerToUpdate.status = e.is_online ? STATUS_GOOD : STATUS_FAIL; }
      // TODO : need to update ...ToUpdate.state also
      if (!e.is_online) {
        // controller is offline
        let controllerToUpdate = { ...e }
        controllerToUpdate.event_time  = API.appScope.getCurrentTime()
        controllerToUpdate.type = 'Controller'

        // remove from alerts all cameras belonging to this controller
        for (let i = state.devicesOff.length - 1; i >= 0; i--) {
          if (state.devicesOff[i].controller_id === e.controller_id) {
            state.devicesOff.splice(i, 1);
          }
        }
        // add this controller to alerts or update (if already exist).
        addOrUpdateAlerts(state, controllerToUpdate, 'controller_id');
        state.alertCount = state.devicesOff.length
      }
      else {
        // Nothing. If the controller is on_line, we will receive event from one of the cameras and process it there
      }
    },
  },
  getters: {
  },
});

export default store;