<template>
  <v-card outlined color="grey lighten-2" class="pt-3">
    <v-alert class="over" dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
      {{ $t(alert_message) }}
    </v-alert>
    <v-form ref="form" lazy-validation :readonly="!isEditAllow">   
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field v-if="!isNewItem" class="font_size_small" readonly dense
            v-model="editedItem.site_id"
          >
            <v-icon :class="[statusClass]" slot="prepend">
              mdi-home-city-outline
            </v-icon>
        </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.site_name"
            v-bind:label="$t('Name')"
            :id="isNewItem ? 'noname' : editedItem.site_id"
            :rules="[rules.required]"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
       </v-row>
       <v-row>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.site_desc"
            v-bind:label="$t('Description')"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field dense
            v-model="editedItem.location"
            v-bind:label="$t('Location (lat, long)')"
            :rules="[rules.required]"
            :hint="locationHint"
          >
            <template #message="{ message }">{{ $t(message) }}</template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn color="error" outlined small v-if="isEditAllow && !isNewItem" :disabled="isNewItem" @click="deleteSite"> {{ $t("Delete") }} </v-btn>
        <v-btn color="gray"  outlined small :disabled="!isNewItem" v-if="isNewItem" @click="cancel"> {{ $t("Cancel") }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" outlined small v-if="isEditAllow" @click="save"> {{ $t("Save") }} </v-btn>
      </v-card-actions>
    </v-form>
    <dialogDelete :visible="dialogDelete" @close="dialogDelete=false" @confirm="deleteItemConfirm"></dialogDelete>
  </v-card>
</template>
<script>
import API from './../api';
import dialogDelete from './DialogDelete.vue';

export default {
  name: 'SiteItem',
  props: { 'item' : Object },
  components: { dialogDelete },
  data() {
    return {
      rules: {
        required: v => !!v  || 'Item is required',
        number: v => /^-?\d+(\.\d+)?$/.test(v) || 'Please enter a number'
      },
      dialogDelete: false,
      alert_message: '',
      alert: false,
      alert_type: 'warning',
      updated: false,
      loading: true,
      editedItem: { ...this.item }
    };
  },
  watch: {
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},3000)
      }
    }
  },
  computed: {
    isEditAllow () { 
      return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
    },
    isNewItem () {
      return (!this.editedItem.site_id) ? true : false
    },
    statusClass() {
      return this.item.state ? API.appConst.LIVE_STATUS_GOOD.type : API.appConst.LIVE_STATUS_FAIL.type
    },
    locationHint() {
      return (!API.appScope.validateLocation(this.editedItem.location)) ? 'The numbers must be must be decimal (-90 to 90 for latitude, -180 to 180 for longitude). Example: 38.8951, -77.0364' : ''
    }
  },
  mounted() {
    this.$refs.form ? this.$refs.form.resetValidation() : ''

    let site_name = document.getElementById('noname');
    if (site_name) { site_name.focus(); }
  },
  methods: {   
    save () {
      const isValid = this.$refs.form.validate();
      if (!isValid) return
      this.editedItem.site_location = this.editedItem.location

      if (this.editedItem.site_id) {
        API.editSite(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.$emit('update', this.editedItem, 'site');
            this.alert_type     = 'success';
            this.alert_message  = 'Site successfully changed';
            this.alert          = true;
          }
          else {
            this.alert_type    = 'warning';
            this.alert_message = this.$t("Changes not saved:") + response.data.error.message;
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Changes not saved:") + error;
          this.alert         = true;
        })
        .finally(() => {
          this.loading = false;
        })
      }
      else {
        // new Site
        API.addSite(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.editedItem.site_id = response.data.data

            this.$emit('add', this.editedItem, 'site');

            this.alert_type     = 'success';
            this.alert_message  = 'Site successfully created';
            this.alert          = true;
          }
          else {
            this.alert_type    = 'error';
            this.alert_message = this.$t("Site creation error:") + response.data.error.message
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Site creation error:") + error.message;
          this.alert         = true;
        })
        .finally(() => {
          this.loading = false;
        })
      }
    },
    cancel () {
      this.$emit('cancel');
      this.$refs.form.resetValidation();
    },
    deleteSite() {
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      API.deleteSite(this.editedItem.site_id)
        .then((response) => {
          if (response.data.isOk) {
            this.$emit('delete', this.editedItem);
            this.alert_type     = 'success';
            this.alert_message  = 'Site successfully deleted';
            this.alert          = true;
          }
          else
          {
            this.alert_type    = 'error';
            this.alert_message = this.$t("Site deletion error:") + response.data.error.message
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Site deletion error:") + error.message;
          this.alert         = true;
        })
        .finally(() => {
          this.loading = false
          this.dialogDelete = false
        })
    },
  },
}
</script>

<style scoped>
.row + .row { margin-top: 2px;}
.row.exp > div {
  padding: 1px!important;
  font-size: .9em;
}
button.tooltip { position: absolute; top: 12px;}
.btn-edit { padding-left: 2px!important;}
.v-card__actions { padding: 8px 10px!important;}
</style>
