<template>
  <v-dialog v-model="show" max-width="350px">
    <v-card>
      <v-card-title class="text-center">{{ $t("Are you sure you want to delete this item?") }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="error" outlined small @click="deleteConfirm">{{ $t("Delete") }}</v-btn>
          <v-btn color="gray"  outlined small @click="show=false">{{ $t("Cancel") }}</v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogDelete",
  data() {
    return {
    };
  },
  props: { 
    'visible' : Boolean,
  },     
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  created () {

  },
  methods: {   
    deleteConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>