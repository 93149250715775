import Vue from "vue";
import VueRouter from "vue-router";

import API from './../api'

import Login from './../views/Login.vue'
import Map from '../views/Map.vue'
import Site from '../views/Site.vue'
import Setup from '../views/Setup.vue'
import LaneList from './../components/LaneList.vue';
import CameraList from './../components/CameraList';
import UserList from './../components/UserList.vue';
import UserSettings from './../components/UserSettings.vue';

import Page404 from '../components/Page404.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', props: true, component: Login, name: 'Login',
    meta: {
      authRequired: 'false',
    }, 
  },
  { path: '/map', props: true, component: Map, name: 'Map', 
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: '/site', props: true, component: Site, name: 'Site', 
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: "/lanes", props: true, component: LaneList, name: 'LaneList',
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: "/cameras", props: true, component: CameraList, name: 'CameraList',
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: '/users', props: true, component: UserList, name: 'UserList',
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: '/setup', props: true, component: Setup, name: 'Setup',
    meta: {
      authRequired: 'true',
    }, 
  },
  { 
    path: '/logout', name: 'Logout',     
    meta: {
      authRequired: 'false',
    }, 
  }, 
  { 
    path: '/user_settings', component: UserSettings, name: 'UserSettings',     
    meta: {
      authRequired: 'true',
    }, 
  }, 
  {
    path: '*', component: Page404,
  },

  /*
  { path: '/alert', props: true, component: Alert, name: 'Alert', 
    meta: {
      authRequired: 'true',
    }, 
  },
  { path: "/sites", props: true, component: SiteList, name: 'SiteList',
    meta: {
      authRequired: 'true',
  },
  */
]

const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired === 'true') {
    console.log('Role:', API.appScope.theCurrUserRole);
    if (!API.appScope.isLoggedIn()) next({ name: 'Login'});
    else next()
  }
  else next()
});

export default router;