<template>
  <v-card
    class="mx-auto my-12"
    max-width="374"
  >
    <v-card-title>
      <v-icon x-large color="black">mdi-emoticon-sad-outline</v-icon>
      <h3>{{ $t("PAGE NOT FOUND") }}</h3>
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  name: 'Page404',
}
</script>

<style>
.v-card__title {
    flex-direction: column;
}</style>