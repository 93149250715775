<template>
 <section>
  <v-app-bar 
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        color="gray" dark dense>
    <v-img
      contain
      max-height="50"
      max-width="150"
      src="./../assets/logo/logo-transparent-png.png"
    ></v-img>

    <v-app-bar-nav-icon v-if="isLogin" class="d-block d-sm-none order-2" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
  
    <router-link class="router-link d-none d-sm-block"
      v-for="item in items"
        :key="item.title"
        :to="item.route"
      >
      <template v-if="item.title === 'Site'">
          <v-col class="fill-height col-sm" :class=item.title>
            <template>
              <div v-if="isLogin && $store.state.alertCount > 0" class="badge-container">
                <div class="badge-content right">{{ $store.state.alertCount }}</div>
              </div>
              <v-icon>{{ item.icon }}</v-icon>
              {{ $t(item.title) }}
            </template>
          </v-col>          
        </template>
        <template v-else>
          <v-col class="fill-height col-sm" :class=item.title>
            <v-icon>{{ item.icon }}</v-icon>
            {{ $t(item.title) }}
          </v-col>
        </template>
    </router-link >
    <v-fab-transition>
      <v-btn v-if="isLogin" class="tooltip ma-2" fab small color="#37474f" @click="$router.push('/user_settings').catch(error => {})">
        <span class="tooltiptext">{{ $t('Change password') }}</span>
        <v-icon>mdi-account-edit</v-icon>
      </v-btn>
    </v-fab-transition>
    <Lang></Lang>
  </v-app-bar>

  <v-navigation-drawer 
    v-model="drawer"
    absolute
    :right ="$i18n.locale === 'en' ? false : true"
    temporary
    width="220px"
  >
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="gray"
      >
        <v-list-item 
          v-for="item in items"
            :key="item.title"
            :to="item.route"
            link
          >
            <template v-if="item.title === 'Site'">
              <v-list-item-content><v-list-item-title>{{ $t(item.title) }}</v-list-item-title></v-list-item-content>
              <div v-if="isLogin && alertCount > 0" class="badge-container">
                <div class="badge-content left">{{ alertCount }}</div>
              </div>
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
            </template>
            <template v-else>
              <v-list-item-content><v-list-item-title>{{ $t(item.title) }}</v-list-item-title></v-list-item-content>
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
            </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
 </section>
</template>

<script>
import Lang from './MenuLang.vue';
import API from './../api';
import { eventBus } from './../main';

export default {
  name: "NavDrawer",
  components: {  Lang },
  data() {
    return {
      drawer: false,
      group: null,
      isLogin: API.appScope.isLoggedIn(),
      items_default: [],
      items_user: [
        { title: "Map", icon: "mdi-map-marker-multiple-outline", route: "/map" },
        { title: "Site", icon: "mdi-home-city-outline", route: "/site" },
        { title: "Lanes", icon: "mdi-road", route: "/lanes" },
        { title: "Cameras", icon: "mdi-camera-outline", route: "/cameras" },
        { title: "Logout", icon: "mdi-logout", route: "/logout", class:"logout" },
        ],
        items_admin: [
        { title: "Map", icon: "mdi-map-marker-multiple-outline", route: "/map" },
        { title: "Site", icon: "mdi-home-city-outline", route: "/site" },
        { title: "Lanes", icon: "mdi-road", route: "/lanes" },
        { title: "Cameras", icon: "mdi-camera-outline", route: "/cameras" },
        { title: "Users", icon: "mdi-account-search", route: "/users" },
        { title: "Setup", icon: "mdi-cog-outline", route: "/setup" },
        { title: "Logout", icon: "mdi-logout", route: "/logout", class:"logout" },
        ],
      mini: true,
    };
  },   
  mounted() {
    console.log('ND:', API);
    eventBus.$on('isLoginEvent', this.loginEvent);
  },
  computed: {
    alertCount () {
      return this.$store.state.alertCount
    },
    items () 
    {
      if (!this.isLogin) {
        return this.items_default;
      } else {
        return API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN ? this.items_admin : this.items_user;
      }
    }
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route (to) {
      //console.log('WATCH: Go to:',to.name)
      if (to.name === 'Logout') {
        API.appScope.doLogout();
        this.$store.commit('resetState');
        if (this.$route.path !== '/') this.$router.push('/').catch();
      }
    },
  },
  methods: {   
    loginEvent (event) {
      this.isLogin = event.login
    },
  }
}
</script>

<style>
#user_settings {display: none;}
.v-navigation-drawer {
  top: 20px!important;
}
.v-navigation-drawer__content {
    width: 100%!important;
}
.v-toolbar__content {
    padding: 4px 12px 4px 0px;
}
.router-link {
  width: 70px;
  height: 58px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color:aliceblue!important;
  font-family:Roboto, sans-serif!important;
  letter-spacing: .09em!important;  
}
a.router-link:hover {
  color: #7bf77f!important;
}
a.router-link:hover .Logout {
  color:coral!important;
}
a.router-link:hover .theme--dark.v-icon{
  color:#4caf50;
}
a.router-link:hover .Logout .theme--dark.v-icon {
  color:coral;
}
.router-link-exact-active.router-link-active {
  background-color: gray;
}

.v-list-item-group a:last-of-type {
  border-top: 1px solid gray;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.v-toolbar__content a:last-of-type {
  _border-left: 1px solid gray;
  _margin-left: 18px;
}

.Logout, .Logout .v-icon {color: #f8a384;}
.col {
    padding: 10px!important;
    display: grid;
}
.badge-container {
  position: relative;
  display: inline-block;
}

.badge-content {
  position: absolute;
  top: -6px;
  background-color: orangered;
  color: white;
  font-size: 10px;
  border-radius: 6px;
  padding: 0 4px;
}

.badge-content.right { left: 40px;}
.badge-content.left  { right: -15px;}
.v-chip.reload { position: absolute; top: 46px; right: 7px; z-index: 999; font-size: 12px; height: 26px;}
.v-chip.reload button.v-icon.mdi {font-size: 16px!important;}
</style>