<template>
  <v-card class="ma-0 pa-1" color="grey lighten-2">    
      <v-container>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Id')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.site_id" hide-details class="font_size_small"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Name')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.site_name" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Description')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.site_desc" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Location')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9">
            <v-text-field readonly v-bind:value="item.site_location" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3">
            <h5>{{ $t('Site structure')}}</h5>
          </v-col>
          <v-col cols="9" sm="9" md="9" class="col-with-tree" align-self="start">
            <SiteCardTree :site_id = item.site_id :is_open = false></SiteCardTree>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>

<script>
import SiteCardTree from './SiteCardTree.vue';

export default {
    name: "SiteCard",
    components: { SiteCardTree },
    data() {
        return {
        };
    },
    props: { 'item' : Object },
    methods: {},
 }
</script>

<style scoped>  
  .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
  }
  .v-input { font-size: 14px!important;}
  .v-input.font_size_small { font-size: .85em!important;}
  .row + .row {
    margin-top: 2px;
  }
  .row > div.col-sm-3.col-md-3.col-3 { padding: 0; }
  .row > div.col-sm-9.col-md-9.col-9 { padding: 0; }
  .v-text-field input { padding: 8px 0 0px!important;}
  .col-with-tree {padding-left: 4px;}
  .container {padding: 0 2px 12px 16px;}
</style>
