<template>
  <div class="list justify-center mt-0 pa-0">
    <gmap-map 
        :zoom="options.zoom"    
        :center="options.center"
        style="width:100%;  height: 90vh;"
        v-bind="options"
        @click="onMapClick"
      >
        <gmap-cluster
          :gridSize="100"
          :zoomOnClick="true"
          :enableRetinaIcons="true"
          :minimumClusterSize="3"
          ref="cluster"
          @click="clusteredMarkers($event)"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in locationMarkers"
            :position="m.position"
            :icon= "markerIcon(m.status)"
            @click="openMarker(m.id)"
          >
            <gmap-info-window
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id"
            >
              <SiteCard v-if="openedMarkerID === m.id" :item = locationMarkers[currSiteIndex]></SiteCard>
            </gmap-info-window>

          </gmap-marker>
        </gmap-cluster>
    </gmap-map>
  </div>
</template>
 
<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import SiteCard from '../components/SiteCard.vue';

export default {
  name: "Map",
  components: { GmapCluster, SiteCard },
  data() {
    return {
      options: {
        center: { lat: 39.51092974597083, lng: -99.24443220209253},
        zoom: 12,
      },
      openedMarkerID: null,
      currSiteIndex: null,
    };
  },
  created () {
  },
  mounted () {
    this.locateGeoLocation();
  },
  computed: {
    locationMarkers () {
      return this.$store.state.locationMarkers
    }
  },
  methods: {
    markerIcon(status) {
      return {
        path : 'M16,3C10.5,3,6,7.5,6,13c0,8.4,9,15.5,9.4,15.8c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C17,28.5,26,21.4,26,13 C26,7.5,21.5,3,16,3z M16,17c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S18.2,17,16,17z',
        fillColor: (status == 'fail' ? 'red' : (status == 'good' ? 'green' : 'yellow')),
        fillOpacity: 1,
        strokeColor: '#555',
        strokeWeight: 1,
        scale: 1.2
      };
    },
    onMapClick() {
      //console.log(e)
      this.openMarker(null)
    },
    openMarker(id) {
       this.openedMarkerID = id
       this.currSiteIndex = this.locationMarkers.findIndex(el => el.site_id === id);
    },
    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.options.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    },
    clusteredMarkers() {
      //console.log(event.getMarkers());
    }
  }
};
</script>
<style>
.marker_info {
  padding: 5px;
  font-weight: 500;
}
.gm-style-iw.gm-style-iw-c {
  min-width: 330px!important;
  max-width: 550px!important;
}
</style>