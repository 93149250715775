<template>
  <v-footer dark padless fixed>
    <v-card class="flex" flat>
      <v-card-actions class="pa-2 white--text">
        <span class="caption">
          © {{ new Date().getFullYear() }}
          <a href="https://omniq.com/" target="_blank">omniQ Inc.</a>
          All Rights Reserved
        </span>
        <v-spacer></v-spacer>
        <span class="caption">Build: {{ buildBranch }} {{ buildNumber }}</span>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>
  
<script>
  export default {
    name: 'Footer',
    data() {
      return {
        buildBranch: null,
        buildNumber: null,
      }
    },
    mounted() {
      const file = "/version.js";
  
      fetch(file)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split('\n')
  
          for (const line of lines) {
            if (line.includes('buildInfo.branch')) {
              this.buildBranch = line.split(':')[1].trim().replace(/'/g, '')
            }
            else if (line.includes('buildInfo.buildNumber')) {
              this.buildNumber = line.split(':')[1].trim().replace(/'/g, '')
            }
          }
        })
        .catch((error) => {
          console.error("Error reading the file:", error)
        })
    },
  }
</script>
<style>
  .v-card .caption a { color:white; text-decoration: none;}
</style>
  