<template>
  <v-card class="settings elevation-2 outlined mx-auto my-12" 
          max-width="350"
          color="grey lighten-3"
          >
    <v-toolbar dark>
      <v-toolbar-title>{{ $t("Change password") }}</v-toolbar-title>
    </v-toolbar>
    <v-alert dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
      {{ $t(alert_message) }}
    </v-alert>
    <v-card-text>
      <v-form ref="passwordForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-text-field color="success"
          v-model="oldPassword"
          :rules="[v => !!v || 'Item is required']"
          v-bind:label="$t('Old password')"
          class="shrink"
          type="password"
          prepend-icon="mdi-lock"
          @input = "error = false"
          required
        >
          <template #message="{ message }">{{ $t(message) }}</template>
        </v-text-field>
        <v-text-field color="success"
          v-model="newPassword"
          :rules="[v => !!v || 'Item is required']"
          v-bind:label="$t('New password')"
          class="shrink"
          type="password"
          prepend-icon="mdi-lock-outline"
          @input = "error = false"
          required
        >
          <template #message="{ message }">{{ $t(message) }}</template>
        </v-text-field>
        <v-text-field color="success"
          v-model="confirmPassword"
          :rules="[v => !!v || 'Item is required', newPassword === confirmPassword || 'The password confirmation does not match']"
          v-bind:label="$t('Confirm new password')"
          class="shrink"
          type="password"
          prepend-icon="mdi-lock-outline"
          @input = "error = false"
          required
        >
          <template #message="{ message }">{{ $t(message) }}</template>
        </v-text-field>
          
        <div v-if="error" class="auth-error error--text">
          {{ $t(error) }}
        </div>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn type='submit'  color="success">{{ $t("Save") }}</v-btn>
          <v-btn @click="close" color="grey lighten-1">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import API from './../api';

export default {
  name: "UserSettings",
  data() {
    return {
      user: API.appScope.theCurrUser,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      alert: false,
      alert_type: 'warning',
      alert_message: '',
      error: false,
      valid: false,
    };
  },
  watch: {
    alert(new_val){
      if(new_val){
        setTimeout(() => {this.alert = false},3000)
      }
    }
  },
  methods: {
    save () {
      const { user, oldPassword, newPassword } = this

      const isValid = this.$refs.passwordForm.validate();
      if (!isValid) return

      API.changePassword({ user, oldPassword, newPassword })
      .then(response => {
        if (response.data.isOk) {
          this.error = false
          this.alert_type    = 'success'
          this.alert_message = 'Password changed successfully'
          this.alert = true
          this.resetForm();
        }
        else {
          this.alert_type    = 'warning';
          this.alert_message = 'User not found or wrong old password'
          this.alert = true
        }
      })
      .catch(error => {
        this.alert_type    = 'warning';
        this.alert_message = 'User not found or wrong old password' + error
        this.alert = true
      })
    },
    close () {
      this.$router.push('/site')
    },
    resetForm () {
      this.$refs.passwordForm.reset()
      this.$refs.passwordForm.resetValidation()
    },
  }
};
</script>

<style scoped>
.auth-error {
  margin-top: -20px;
  padding-left: 33px;
  font-size: 12px;
  letter-spacing: 0;
}
::v-deep .v-text-field .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}
::v-deep .theme--light.v-input input {
  color: rgba(0, 0, 0, 0.6);
}
</style>
