<template>
<div id="app">
  <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <v-app id="insire">
      <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
      <v-card v-else
        class="overflow-hidden"
        style="position: relative;"
        height="100vh"
      >
        <NavDrawer></NavDrawer>  
        <v-sheet
          id="scrolling-techniques-7"
          class="overflow-y-auto"
          height="90vh"
        >
          <router-view></router-view>
        </v-sheet>
        <Footer></Footer>
      </v-card>
    </v-app>
  </section>
</div>
</template>

<script>
import NavDrawer from "./components/NavDrawer.vue";
import Footer from './components/Footer.vue';
import Errored from './components/Errored.vue';


import i18n from "@/i18n"
import API from './api';
import { eventBus } from './main';

export default {
  data: () => ({
    alerts: [],
    status_good: API.appConst.LIVE_STATUS_GOOD.type,
    status_fail: API.appConst.LIVE_STATUS_FAIL.type,
    loading: true,
    errored: false
  }),
  name: 'App',

  components: { 
    NavDrawer, Errored, Footer
  },
  created () {
    console.log("Active locale: ", i18n.locale)
    this.$vuetify.rtl = (i18n.locale === 'en' ? false : true)
    setTimeout(async () => {
      this.eventSubscription();
      this.initialize();
    }, 0);
  },
  watch: {
    alerts: {
      handler(newData) {
        if (newData) {
          this.$store.state.alertCount = this.alerts.length
        }
      },
      deep: true  // watch for changes within the array elements     
    },
  },
  methods: {   
    async initialize () {
      if (!API.appScope.isLoggedIn()) {
        API.appScope.doLogout();
        this.$store.commit('resetState');
        if (this.$route.path !== '/') this.$router.push('/').catch();
        this.loading = false;
      }
      else {
        try {
          await Promise.all([
            this.loading = true,
            API.getSitesFull().then((r) => { this.$store.state.sites = r; }).catch((error) => { this.errored = !!error; }),
            API.getControllersFull().then((r) => { this.$store.state.controllers = r; }).catch((error) => { this.errored = !!error; }),
            API.getLanesFull().then((r) => { this.$store.state.lanes = r; }).catch((error) => { this.errored = !!error; }),
            API.getCamerasFull().then((r) => { this.$store.state.cameras = r; }).catch((error) => { this.errored = !!error; })
          ]); 

          this.makeSiteTree();

          this.alerts = API.makeDevicesOffAlerts(this.$store.state.cameras, this.$store.state.controllers, this.$store.state.sites);
          this.$store.state.devicesOff = this.alerts;
          this.$store.state.alertCount = this.alerts.length;

          this.$store.state.locationMarkers = this.$store.state.sites.map(site => {
            return {
              ...site ,
              id: site.site_id, 
              position: API.appScope.splitCoordinates(site.site_location)
            }
          });
          this.loading = false;
        } 
        catch (error) { console.error('Error in getAllFull:', error); throw error; }
      }
    },

    makeSiteTree() {
      this.$store.state.tree = this.$store.state.sites.map(site => {
        const siteControllers = this.$store.state.controllers.filter(controller => controller.site_id === site.site_id);

        return {
          id: site.site_id,
          name: site.site_name,
          detail: site,
          type: 'site',
          status: site.status,
          children: siteControllers.map(controller => {
            if (!controller.location) { controller.location = site.location }
            const controllerLanes = this.$store.state.lanes.filter(lane => lane.controller_id === controller.controller_id);

            return {
              id: controller.controller_id,
              name: controller.controller_name,
              detail: controller,
              type: 'controller',
              status: controller.status,
              warning: (controller.warning && controller.state),
              children: controllerLanes.map(lane => {
                if (!lane.location) { lane.location = controller.location }

                const laneCameras = this.$store.state.cameras.filter(camera => camera.lane_id === lane.lane_id);

                return {
                  id: lane.lane_id,
                  name: lane.lane_name,
                  detail: lane,
                  type: 'lane',
                  status: lane.status,
                  children: laneCameras.map(camera => {
                    if (!camera.location) { camera.location = lane.location }
                    return {
                      id: camera.camera_id,
                      name: camera.camera_name,
                      detail: camera,
                      type: 'camera',
                      status: camera.status
                    }
                  }),
                };
              }),
            };
          })
        }
      });

      // adding unassigned cameras (lane_id === null) to their controller
      const unassignedCameras = this.$store.state.cameras.filter(camera => !camera.lane_id)

      for (const treeSite of this.$store.state.tree) {
         for (const treeController of treeSite.children) {
            const uc = unassignedCameras.filter(camera => camera.controller_id === treeController.id);

            if (uc.length > 0) {
              const isAnyCameraStateFalse = uc.some(camera => camera.state === false);

              treeController.children.push({
                id: 0,
                name: this.$t("Cameras out of lanes"),
                detail: {},
                type: 'lane',
                status: isAnyCameraStateFalse ? this.status_fail : this.status_good,
                children: uc.map(camera => {
                  if (!camera.location) { camera.location = treeController.detail.location }
                  return {
                    id: camera.camera_id,
                    name: camera.camera_name,
                    detail: camera,
                    type: 'camera',
                    status: camera.status
                  }
                })
              });
            }
         }
      }
      this.$store.state.treeFiltered = [...this.$store.state.tree]
    },

    eventSubscription () {
      eventBus.$on('isLoginEvent', this.loginEvent);
      eventBus.$on('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent);
      eventBus.$on('onControllerStateChangedEvent', this.onControllerStateUpdatedEvent);

      eventBus.$on('onSiteChangedEvent', this.onSiteChangedEvent);
      eventBus.$on('onSiteAddedEvent', this.onSiteAddedEvent);
      eventBus.$on('onSiteDeletedEvent', this.onSiteDeletedEvent);
    },

    loginEvent (e) {
      this.isLogin = e.login
      this.initialize();
    },

    onCameraStateUpdatedEvent(e) {
      this.$store.commit('onCameraStateUpdatedEvent', e);
    },

    onControllerStateUpdatedEvent(e) {
      this.$store.commit('onControllerStateUpdatedEvent', e);
    },

    onSiteChangedEvent(e) {   
      this.$store.commit('onSiteChangedEvent', e);
    },

    onSiteAddedEvent(e) {     
      this.$store.commit('onSiteAddedEvent', e);
    },

    onSiteDeletedEvent(e) {     
      this.$store.commit('onSiteDeletedEvent', e);
    },
  }

}

</script>
<style>
body {
  font-size: 14px!important;
}
</style>
