<template>
  <div class="list justify-center mt-0 pa-0">
    <SiteTree></SiteTree>
  </div>
</template>

<script>
import SiteTree from '../components/SiteTree.vue';

export default {
  name: 'Site',
  components: { SiteTree },
  data() {
    return {
    };
  } 
}
</script>
