<template>
    <section v-if="errored">
      <Errored></Errored>
    </section>
    <section v-else>
      <div class="list justify-center mb-0 mt-2 pa-0">
        <section>
          <v-row class="pa-2">
            <v-col class="top">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                v-bind:label="$t('Search')"
                single-line
                hide-details
                clearable
                class="pa-0 shrink search-label"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row d-flex no-gutters class="pa-2 pt-2 align-stretch">
            <v-col cols="12" md="4" class="pa-0 border">
              <v-treeview
                :items="items"
                :search="search"
                dense
              >
                <template slot="label" slot-scope="{ item }"> 
                  <v-icon class="mr-2">{{item.icon}}</v-icon>
  
                  <a @click="clickItem($event, item)">
                    {{ $t(item.name) }}
                  </a>
                </template>
              </v-treeview>
            </v-col>
  
            <v-col cols="12" md="8" class="pa-0 item border">
              <AlertMail v-if="curr_mode === 'Alert Mail'"></AlertMail>
            </v-col> 
          </v-row>
        </section>
      </div>
    </section>
  </template>
  
  <script>
  import Errored from './Errored.vue';
  import API from '../api';
  import AlertMail from './AlertMail.vue';
  
  export default {
    name: 'SetupTree',
    components: { Errored, AlertMail },
    data: () => ({
      active: [],
      items: [
        {
          id: 1,
          name: 'Alert Mail',
          icon: 'mdi-email-outline',
          children: [],
        },
        /*
        {
          id: 2,
          name: 'Location',
          icon: 'mdi-map-marker-radius',
          children: [],
        },
        */
      ],
      curr_mode: 'Alert Mail',
      search: null,
      isDisabled: true,
      errored: false,
      error: '',
      updated: false
    }),
    mounted () {
      Array.from(document.getElementsByClassName("selected_item")).forEach( (el => { el.classList.remove("selected_item"); }) )
      document.getElementsByClassName('v-treeview-node__root')[0].classList.add("selected_item");
    },
    created () {
      this.$store.state.reload = false; 
    },
    computed: {
      isEditAllow () { 
        return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
      },
    },
    methods: { 
      clickItem (e, item) {  
        this.curr_mode = item.name
        Array.from(document.getElementsByClassName("selected_item")).forEach( (el => { el.classList.remove("selected_item"); }) ) // remove class 'selected_item' on prev element
        let itemParent = API.appScope.findParentWithClassName(e.target, 'v-treeview-node__root');
        if (itemParent != null) { itemParent.classList.add("selected_item"); }
      },
    }
  }
  </script>
  
  <style scoped>
    ::v-deep .v-card__text {width: 350px;}
    ::v-deep .card_item {padding: 0 2px 0 2px!important;}
    ::v-deep .v-label { font-size: 16px; }
    .v-text-field input { font-size: 1em; padding: 0; }
    .v-input--selection-controls { margin-top: 0; padding-top: 0;}
    ::v-deep .v-select > .v-input__control > .v-input__slot { margin-bottom: 0px!important; }
    .custom-item { display: flex; align-items: center;}
    .custom-icon { margin-right: 8px;}
    ::v-deep .v-list-item__content { padding: 0; }
    ::v-deep .v-list-item__action { margin: 2px 6px 2px 2px!important; }
    ::v-deep .v-list-item__title { font-size: 12px; }
    ::v-deep .v-list-item--link { font-size: 12px; }
    ::v-deep .v-text-field { font-size: 12px; }
    .row.align-content-start > .col-6 { padding-right: 2px; }
    .v-btn:not(.v-btn--round).v-size--default {
      height: 28px;
      min-width: 80px;
      padding: 0 5px;
      margin-right: 3px;
      font-size: 0.7rem;
    }
    button.v-icon.mdi.small { font-size: 18px!important;}
    .v-icon.mdi.mdi-alert-outline { font-size: 22px!important;}
    .v-card__subtitle, .v-card__text, .v-card__title { padding: 0px; }
    ::v-deep .v-treeview > .v-treeview-node.v-treeview-node--click { border-bottom: 1px solid lightgray;}
    ::v-deep .v-treeview > .v-treeview-node.v-treeview-node--click:last-child { border-bottom: none;}
    ::v-deep .v-treeview-node__label {
      display: flex;
      align-items: center;
    }
    .v-treeview-node__label > a {
      color: #424242;
      font-size: 1em;
      padding-right: 20px;
    }
    ::v-deep .v-treeview .v-treeview-node__root { min-height: 40px!important; }
    .v-treeview-node__root .v-treeview-node__label > a { 
      width: 200px;
      overflow: hidden;
      white-space: nowrap; 
      text-overflow: ellipsis;
    }
    .v-treeview-node__children .v-treeview-node__root .v-treeview-node__label > a { 
      width: 200px;
      overflow: hidden;
      white-space: nowrap; 
      text-overflow: ellipsis;
     }
     ::v-deep .v-treeview .v-treeview-node__level { width: 12px!important;}
    ::v-deep .selected_item {background-color: #e0e0e0;}
    .v-avatar {
      border-radius: 5px; 
      width: 30px!important;
      font-size: 11px;
    }
    .v-avatar.ma-1 { top: 1px;}
    .status_select::v-deep .v-select__selection.v-select__selection--comma { margin-bottom: 0; margin-top: 14px}
    .v-input.status_select {
      max-width: 180px;
    }
    .top {
      display: flex;
      max-width: 180px;
      justify-content: flex-start;
      font-size: 12px;
      padding: 0px 12px 2px 0px!important;
      color: rgba(0, 0, 0, 1);
      font-weight: 500;
    }
    .align-stretch {
      display: flex;
      align-items: stretch;
    }
    .border { border: 1px solid #e0e0e0; max-height: 81vh; overflow: overlay; }
    .item { background-color:#e0e0e0;} 
  </style>
  