<template>
  <v-card outlined color="grey lighten-2" class="pa-1 pt-2">
    <v-text-field v-if="loading" color="success" loading disabled></v-text-field>

    <gmap-map v-else-if="position.lat && position.lng"
      :zoom="options.zoom"    
      :center="options.center"
      style="width:100%; height:285px;"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false
      }"
    >
      <gmap-marker :position="position"></gmap-marker>
    </gmap-map>
    <div class="warn" v-else>
      {{ $t('Check the location format.') }}
      {{ $t('The numbers must be must be decimal (-90 to 90 for latitude, -180 to 180 for longitude). Example: 38.8951, -77.0364') }}
    </div>
  </v-card>
</template>
 
<script>
import API from '@/api';

export default {
  name: "MapItem",
  data() {
    return {
      loading: true,
    };
  },
  props: { 
    'location': String,
  },     
  mounted () {    
    this.loading = false
  },
  computed: {
    position: function () {
      return  API.appScope.splitCoordinates(this.location);
    },
    options: function () {
      return {
        center: this.position,
        zoom: 16,
        strokeColor:'#008000'
      }
    },
  },
}
</script>
<style scoped>
  .v-card.v-sheet.theme--light.blue.darken-2 {
    max-width: 500px;
    overflow: hidden;
  }
  .v-btn.v-btn--block { height: 40px; justify-content: flex-end;}
  .warn {
    border: 1px solid gray;
    margin: 6px;
    padding: 10px;
    border-radius: 4px!important;
    font-size: 12px;
    text-align: center;
  }
</style>