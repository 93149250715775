export function addOrUpdateAlerts(state, newDevice, key) {
  let deviceToUpdate = state.devicesOff.find(device => device[key] ===  newDevice[key]);
  if (deviceToUpdate) {
    copyCommonProperties(newDevice, deviceToUpdate)
  } else {
    state.devicesOff.unshift(newDevice);
  }
}

export function copyCommonProperties(source, target) {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key) && Object.prototype.hasOwnProperty.call(target, key)) {
      target[key] = source[key];
    }
  }
}