<template>
    <v-card outlined color="grey lighten-2" class="pa-3" height="100%">
      <v-form ref="setup" v-model="valid" lazy-validation :readonly="!isEditAllow">   
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('Mail server name') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              v-model="editedItem.mail_server_name"
              type="input"
              :rules="[rules.required]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('Port') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              v-model="editedItem.mail_port"
              type="input"
              :rules="[rules.required, rules.number]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('User name') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              v-model="editedItem.mail_user_name"
              type="input"
              :rules="[rules.required]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('Password') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show_pass = !show_pass"
              v-model="editedItem.mail_password"
              :type="show_pass ? 'text' : 'password'"
              :rules="[rules.required]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('From address') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              v-model="editedItem.from_mail_address"
              :rules="[rules.required, rules.email]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('To address') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-text-field 
              dense
              v-model="editedItem.to_mail_address"
              :rules="[rules.required, rules.email]"
            >
              <template #message="{ message }">{{ $t(message) }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" lg="2"><v-subheader>{{ $t('Enable SSL') }}</v-subheader></v-col>
          <v-col cols="7" lg="10">
            <v-checkbox
              v-model="editedItem.enable_ssl"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"></v-col>
          <v-col cols="2">
              <v-btn color="success" outlined small v-if="isEditAllow" @click="save"> {{ $t("Save") }} </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-alert class="over" dense prominent :type="alert_type" :value="alert" transition="scale-transition" icon="mdi-map-marker-alert">
        {{ $t(alert_message) }}
      </v-alert>
    </v-card>
  </template>
  <script>
  import API from './../api';
  
  export default {
    name: 'AlertMail',
    data() {
      return {
        editedItem: {},
        rules: {
            required: v => !!v  || 'Item is required',
            number: v => /^-?\d+(\.\d+)?$/.test(v) || 'Please enter a port number',
            //email: v=> /.+@.+/.test(v) || 'E-mail must be valid',
            email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        },
        valid: false,
        show_pass: false,
        alert_message: '',
        alert: false,
        alert_type: 'warning',
        updated: false,
        loading: true,
      };
    },
    watch: {
      alert(new_val){
        if(new_val){
          setTimeout(() => {this.alert = false},3000)
        }
      }
    },
    computed: {
      isEditAllow () { 
        return (API.appScope.theCurrUserRole === API.appConst.ROLE_ADMIN) 
      },
    },
    mounted() {
      this.initialize()
      this.$refs.setup ? this.$refs.setup.resetValidation() : '' 
    },
    methods: {   
      initialize () {
        API.getAlertMail()
        .then((response) => {
          if (response.data.isOk) {
            this.editedItem = response.data.data
          }
        })
        .catch(error => {
          this.errored = !!error;
        })
        .finally(() => {
            this.loading = false
            this.$refs.setup.resetValidation()
        });
      },
      save () {
        this.valid = this.$refs.setup.validate();
        if (!this.valid) return

        API.editAlertMail(this.editedItem)
        .then((response) => {
          if (response.data.isOk) {
            this.alert_type     = 'success';
            this.alert_message  = 'Changes successfully saved';
            this.alert          = true;
          }
          else {
            this.alert_type    = 'warning';
            this.alert_message = this.$t("Changes not saved:") + response.data.error.message;
            this.alert         = true;
          }
        })
        .catch(error => {
          this.alert_type    = 'error';
          this.alert_message = this.$t("Changes not saved:") + error;
          this.alert         = true;
        })
        .finally(() => {
          this.loading = false;
        })
      },
    },
  }
  </script>
  
  <style scoped>
  .col, .col-7, .col-9, .col-11 {padding: 0px!important;}
  .row + .row { margin-top: 2px;}
  .row.exp > div {
    padding: 1px!important;
    font-size: .9em;
  }
  .v-subheader { height: 20px; align-items: flex-end; padding-bottom: 8px;}
  .btn-edit { padding-left: 2px!important;}
  button.tooltip { position: absolute; top: 12px;}
  .v-card__actions { padding: 8px 10px!important;}
  </style>
  