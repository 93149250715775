<template>
  <v-menu v-model="langMenu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="lang-menu" color="success" v-bind="attrs" v-on="on">
          <v-icon small>mdi-translate</v-icon>
          <v-icon small center>mdi-menu-down</v-icon>
          {{$i18n.locale}}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(lang, index) in langs" :key="index" @click="onMenuLangClick(lang)">
          <v-list-item-title>{{ lang.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>           
</template>

<script>
export default {
  name: "Lang",
  data: () => ({
      langs: [
        { locale: 'en', title: 'English' },
        { locale: 'he', title: 'עברית' }
      ],
    activeLang: 'English',
    langMenu: false,
  }),
  props: { },
  methods: {
    onMenuLangClick (lang) {
      this.activeLang   = lang.title;
      this.$i18n.locale = lang.locale;
      this.$vuetify.rtl = (this.$i18n.locale === 'en' ? false : true)
    },
  }
}
</script>

<style>
.lang-menu.v-btn { 
  margin: 0px;
  width: 70px;
  text-transform: capitalize;
  _margin-right: -4px;
  height: 32px;
}
</style>