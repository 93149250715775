<template>
  <section v-if="errored">
    <Errored></Errored>
  </section>
  <section v-else>
    <div class="list small justify-center ma-0 pa-0">
      <v-text-field v-if="loading" color="success" loading disabled></v-text-field>
      <section v-else>
        <v-treeview v-if="tree.length > 0"
          :items="tree"
          open-on-click
          :open-all="is_open"
          item-key="id"
        >
          <template slot="label" slot-scope="{ item }">            
            <v-icon small class="mr-1" :class="[item.status]">
              mdi-circle
            </v-icon>
            <v-icon v-if="item.type === 'controller' && item.warning" class="mr-1 fail shake-anim" small>mdi-alert-outline</v-icon>
            {{ item.name }}
          </template>

        </v-treeview>
        <span v-else>{{ $t('no information') }}</span>
      </section>
    </div>
  </section>
</template>

<script>
import API from './../api';
import { eventBus } from '../main';

export default {
  name: "SiteCardTree",
  data: () => ({
    tree: [],
    cameras: [],
    lanes: [],
    controllers: [],
    status_good: API.appConst.LIVE_STATUS_GOOD.type,
    status_fail: API.appConst.LIVE_STATUS_FAIL.type,
    errored: false,
    loading: true
  }),
  props: { 'site_id' : String, 'is_open' : Boolean },
  created () {
    eventBus.$on('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent );
    eventBus.$on('onControllerStateChangedEvent', this.onControllerStateUpdatedEvent);
  },
  beforeDestroy () {
    eventBus.$off('onCameraStateChangedEvent', this.onCameraStateUpdatedEvent);
    eventBus.$off('onControllerStateChangedEvent', this.onControllerStateUpdatedEvent);
  },

  mounted () {
    this.initialize()
  },
  methods: {   
    async initialize() {
      try {
        await Promise.all([
          this.getControllersBySite().catch((error) => { throw error; }),
          this.getLanesBySite().catch((error) => { throw error; }),
          this.getCamerasBySite().catch((error) => { throw error; })
        ]); 
        this.makeSiteTree();
        this.loading = false;    
      } 
      catch (error) {
        console.error('Error in initialize:', error);
      }
    },   

    getControllersBySite() {
      let states = []
      return API.getControllersBySite(this.site_id)
      .then((response) => {
        if (response.data.isOk) {
          this.controllers = response.data.data;
          return API.getStateControllers()
            .then((r) => {
              if (r.data.isOk) {
                states = r.data.data;
              }
            })
            .then(() => {
              this.controllers = this.controllers.map(controller => {
                let controller_in_states = states.find(s => s.controller_id === controller.controller_id);
                let state   = controller_in_states?.is_online || false;
                let warning = controller_in_states?.controller_has_warning || false;
                return {
                  ...controller, 
                  state: state,
                  warning: warning 
                }
              });
            })
        }
        else {
          throw new Error('Error in getCameras');
        }
      })
    },
    getLanesBySite() {
      let states = []
      return API.getLanesBySite(this.site_id)
      .then((response) => {
        if (response.data.isOk) {
          this.lanes = response.data.data;
          return API.getStateLanes()
            .then((r) => {
              if (r.data.isOk) {
                states = r.data.data;
              }
            })
            .then(() => {
              this.lanes = this.lanes.map(lane => {
                const state = states.find(s => s.lane_id === lane.lane_id)?.is_online || false;
                return {
                  ...lane, 
                  state: state 
                }
              });
            })
        }
        else {
          throw new Error('Error in getCameras');
        }
      })
    },
    getCamerasBySite() {
      let states = [];
      return API.getCamerasBySite(this.site_id)
      .then((response) => {
        if (response.data.isOk) {
          this.cameras = response.data.data;
          return API.getStateCameras()
            .then((r) => {
              if (r.data.isOk) {
                states = r.data.data;
              }
            })
            .then(() => {
              this.cameras = this.cameras.map(camera => {
                const state = states.find(s => s.camera_id === camera.camera_id)?.is_online || false;
                return {
                  ...camera,
                  state: state 
                }
              });
           })
        }
        else {
          throw new Error('Error in getCameras');
        }
      })
    },

    makeSiteTree() {
      this.tree = this.controllers.map(controller => {
      const controllerLanes = this.lanes.filter(lane => lane.controller_id === controller.controller_id);

      return {
        id: controller.controller_id,
        name: controller.controller_name,
        type: 'controller',
        status: controller.state ? this.status_good : this.status_fail,
        warning: (controller.warning && controller.state),
        children: controllerLanes.map(lane => {
          const laneCameras = this.cameras.filter(camera => camera.lane_id === lane.lane_id);

          return {
            id: lane.lane_id,
            name: lane.lane_name,
            status: lane.state ? this.status_good : this.status_fail,
            children: laneCameras.map(camera => ({
              id: camera.camera_id,
              name: camera.camera_name,
              status: camera.state ? this.status_good : this.status_fail,
            })),
          };
        }),
      };
    });

    // adding unassigned cameras (lane_id === null) to their controller
    const unassignedCameras = this.cameras.filter(camera => !camera.lane_id)

    for (const controller of this.tree) {
      const uc = unassignedCameras.filter(camera => camera.controller_id === controller.id);
      if (uc.length > 0) {
        controller.children.push({
          id: 0,
          name: "Cameras out of lanes",
          status: null,
          children: uc.map(camera => ({
            id: camera.camera_id,
            name: camera.camera_name,
            status: camera.state ? this.status_good : this.status_fail
          }))
        });
      }
    }
   },

   onCameraStateUpdatedEvent(event) {
      // update tree
      let e = event.state;
      if (!e.lane_id) e.lane_id = 0; // lineId for unassigned cameras (event lane_id === null)

      let controllerToUpdate = API.appScope.findTreeItemById(this.tree, e.controller_id);
      if (controllerToUpdate) {
        controllerToUpdate.warning = e.controller_has_warning
        controllerToUpdate.status  = e.controller_is_online ? this.status_good : this.status_fail;

        let laneToUpdate = API.appScope.findTreeItemById(controllerToUpdate.children, e.lane_id);
        if (laneToUpdate) { 
          laneToUpdate.status = e.lane_is_online ? this.status_good : (!e.lane_is_online ? this.status_fail : null);

          let cameraToUpdate  = API.appScope.findTreeItemById(laneToUpdate.children, e.camera_id);
          if (cameraToUpdate) { 
            cameraToUpdate.status = e.camera_is_online ? this.status_good : this.status_fail; 
          }
        }
      }
    },

    onControllerStateUpdatedEvent(event) {
      let e = event.state;
      // update Tree

      let controllerToUpdate = API.appScope.findTreeItemById(this.tree, e.controller_id);
      if (controllerToUpdate) {
        controllerToUpdate.status  = e.controller_is_online ? this.status_good : this.status_fail;
        controllerToUpdate.warning = e.controller_has_warning
      }
    },
  }
}
</script>

<style scoped>  
  .v-card {max-width: 350px}

  ::v-deep .v-treeview-node .v-treeview-node__root {
    min-height: 28px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  ::v-deep .v-treeview-node__label {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .v-treeview-node__label > a {
    color: #424242;
    font-size: .875em;
    padding-right: 20px;
  }
  .v-treeview-node__root .v-treeview-node__label > a { 
    width: 180px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
  }
  .v-treeview-node__children .v-treeview-node__root .v-treeview-node__label > a { 
    width: 90px; 
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
  }
</style>
