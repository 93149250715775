<template>
  <section>
    <div align-self="center" class="_alerts ma-1">
      <v-alert class="small" dense type="error">{{ alertCount }} {{ $t("alerts found") }}</v-alert>
      <v-item-group>
        <v-item 
          v-for="(item, index) in devicesOff"
          :key="index"           
          :value="item"
        >
          <v-alert v-if="item.type === 'Camera'" class="small" dense  color="red" text outlined :icon="false" type="error" :value="true" transition="scale-transition">
            <div class="d-flex align-center justify-space-between"> 
              <div>
                <v-icon class="red--text" :title="item.camera_ip">mdi-camera-outline</v-icon>
                {{item.event_time}} {{item.site_name}} : {{item.controller_name}} : {{item.lane_name}} : {{item.camera_name}}
              </div>
            </div>
          </v-alert>
          <v-alert v-else class="small" dense color="red" border="left" text outlined :icon="false" type="error" :value="true" transition="scale-transition">
            <div class="d-flex align-center justify-space-between"> 
              <div>
                <v-icon class="red--text" :title="item.controller_ip">mdi-card-bulleted-outline</v-icon>
                {{item.event_time}} {{item.site_name}} : {{item.controller_name}}
              </div>
            </div>
          </v-alert>
        </v-item> 
        </v-item-group>
    </div>
  </section>
</template>

<script>

export default {
  name: 'StatusAlerts',
  data: () => ({
  }),
  computed: {
    devicesOff () {
      return this.$store.state.devicesOff
    },
    alertCount () {
      return this.$store.state.alertCount
    }
  }
}
</script>

<style scoped>
.container { padding-bottom: 0; }
button.v-icon.mdi.small { font-size: 24px!important;}

i.v-icon.v-icon {font-size: 24px!important;}

.v-sheet.v-card.alert {
    border: 1px solid #F44336 !important;   
    border-radius: 6px;
}
.v-sheet.v-card.alert h4 { background-color: #F44336 !important; opacity: 0.8; font-weight:100 ; color:white !important; padding: 5px;}

._alerts .v-alert.small {
    margin: 0px 0px 4px 0px!important;
    position: relative!important;
    z-index: 0;
    border-radius: 3px!important;
    font-size: 14px!important;
    width: 100%!important;
}
._alerts .v-alert.small .v-alert__icon.v-icon {
    font-size: 24px!important;
}
._alerts .v-alert__icon {
    height: 40px!important;
    min-width: 40px;
}
._alerts .v-alert {
  bottom: 0px;
  right: 0px;
}
.v-alert--outlined { border: none!important;}
.col { padding: 4px!important; }
.col .alert {overflow-y: auto;}
::v-deep .v-treeview.details {border-color: #F44336!important}

@media (max-width: 590px)
{ 
  .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-6 .alert {height: 220px !important; overflow-y: auto;}
}
</style>
